export default defineAppConfig({
    system: {
        serviceAuthHeaderName: '',
        routers: {
            minNameLength: 3,
            maxNameLength: 30,
        },
    },
});

declare module '@nuxt/schema' {
    interface AppConfigInput {
        system?: {
            /** Project name */
            serviceAuthHeaderName: string;
            routers: {
                minNameLength: number;
                maxNameLength: number;
            };
        };
    }
}
