<template>
    <v-app-bar
        class="app-header elevation-10"
        color="appbar"
        density="compact"
        scroll-behavior="elevate"
        style="overflow: visible">
        <div class="d-flex flex-grow-1 align-center px-4">
            <div class="d-flex">
                <nuxt-link to="/">
                    <img
                        height="25"
                        src="/zetta.svg" />
                </nuxt-link>
                
            </div>

            <div
                id="searchbar"
                class="flex-grow-1"></div>

            <div class="flex-column">
                <div class="d-flex justify-end align-center">
                    <slot name="append" />
                    <div v-if="status === 'authenticated'">
                        <v-menu
                            v-model="menuOpen"
                            :close-on-content-click="false"
                            :location="'bottom'">
                            <template #activator="{ props }">
                                <app-primary-btn
                                    variant="text"
                                    append-icon="$chevron-down"
                                    v-bind="props">
                                    <div class="text-white">
                                        {{ username }}
                                    </div>
                                </app-primary-btn>
                            </template>
                            <app-user-action-overlay
                                :username="username"
                                :email="email"
                                @close="menuOpen = false" />
                        </v-menu>
                    </div>
                    <div
                        v-if="status === 'unauthenticated'"
                        class="text-low-emphasis text-body-2">
                        <nuxt-link to="/login">Login</nuxt-link>
                        <template v-if="runtimeCfg.public.enableRegistration">
                            or
                            <nuxt-link to="/register">Register now</nuxt-link>
                        </template>
                    </div>
                    <app-loader v-if="status === 'loading'" />
                </div>
            </div>
        </div>
    </v-app-bar>
</template>
<script setup lang="ts">
import { useTimeout } from '@vueuse/core';

const showDebugs = ref(false);
const { status, data } = useAuth();
const menuOpen = ref(false);
const showLogout = ref(false);
const runtimeCfg = useRuntimeConfig();

watchEffect(() => {
    if (showLogout.value) {
        const n = useTimeout(3000);
        const s = watchEffect(() => {
            if (n.value) {
                showLogout.value = false;
                s();
            }
        });
    }
});

const username = computed(() => {
    return data.value?.user?.name || 'Unnamed';
});
const email = computed(() => {
    return data.value?.user?.email || 'Unnamed';
});
</script>
<style lang="scss" scoped>
.email-label {
    line-height: 14px;
}
.app-header {
    --v-shadow-key-umbra-opacity: 0.8;
    --v-shadow-key-penumbra-opacity: 0.4;
    --v-shadow-key-ambient-opacity: 1;
}
</style>
