import { OrgKind } from '@ZettaScaleLabs/continuum-node-client/dist/organization';
import { DateTime } from 'luxon';

export default defineNuxtRouteMiddleware(async (to) => {
    if (to.meta.auth === false) {
        return;
    }

    const { status } = useAuth();

    if (status.value === 'unauthenticated') {
        return;
    }

    const organisationsStore = useOrganisationsStore();
    const { organisations } = storeToRefs(organisationsStore);

    if (!organisations.value.length) {
        await organisationsStore.fetchOrganisations();
    }

    const org = organisations.value.at(0);

    if (!!org && org.kind === OrgKind.DEMO && org.expirationDate) {
        const expDate = DateTime.fromISO(org.expirationDate);

        if (expDate < DateTime.now()) {
            if (to.meta.allowExpired) return;

            return navigateTo('/organisation/upgrade');
        }
    }
});
