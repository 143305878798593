import { createI18nMessage } from '@vuelidate/validators';
import * as validators from '@vuelidate/validators';

export default (i18nInstance?: any) => {
    const { t } = i18nInstance ? { t: i18nInstance } : useI18n();
    const withI18nMessage = createI18nMessage({ t });
    const forbiddenKeyExpr =
        useRuntimeConfig().public.dashboard.forbiddenKeyExpressions;

    function translate(key: string) {
        return ({ $params }: { $params: any }) => {
            return t(key, $params);
        };
    }
    return {
        shouldMatch: withI18nMessage(shouldMatch, {
            messagePath: translate('validations.shouldMatch'),
            withArguments: true,
        }),
        shouldNotMatch: withI18nMessage(shouldNotMatch, {
            messagePath: translate('validations.shouldNotMatch'),
            withArguments: true,
        }),
        forbiddenKeyExpr: withI18nMessage(
            (value: string) => !forbiddenKeyExpr.includes(value),
            {
                messagePath: translate('validations.forbiddenKeyExpr'),
            }
        ),
        shouldMatchDDSFrequencyPattern: withI18nMessage(
            shouldMatch(/^.+=\d+$/),
            {
                messagePath: translate(
                    'validations.shouldMatchDDSFrequencyPattern'
                ),
            }
        ),
        shouldBeRegex: withI18nMessage(shouldBeRegex, {
            messagePath: translate('validations.shouldBeRegex'),
        }),
        shouldBeChecked: withI18nMessage(shouldBeChecked, {
            messagePath: translate('validations.shouldBeChecked'),
        }),
        shouldBeAvailableDomain: withI18nMessage(shouldBeAvailableDomain, {
            messagePath: translate('validations.shouldBeAvailableDomain'),
        }),
        shouldBeX509: withI18nMessage(shouldBeX509, {
            messagePath: translate('validations.shouldBeX509'),
        }),
        shouldOpenCertificate: withI18nMessage(shouldOpenCertificate, {
            messagePath: translate('validations.keyDoesntMatch'),
            withArguments: true,
        }),
        shouldBeSignedBy: withI18nMessage(shouldBeSignedBy, {
            messagePath: translate('validations.shouldBeSignedBy'),
            withArguments: true,
        }),
        required: withI18nMessage(validators.required, {
            messagePath: translate('validations.required'),
        }),
        numeric: withI18nMessage(validators.numeric, {
            messagePath: translate('validations.shouldBeNumeric'),
        }),
        minLength: withI18nMessage(validators.minLength, {
            withArguments: true,
            messagePath: translate('validations.minLength'),
        }),
        maxLength: withI18nMessage(validators.maxLength, {
            withArguments: true,
            messagePath: translate('validations.maxLength'),
        }),
        minValue: withI18nMessage(validators.minValue, {
            withArguments: true,
            messagePath: translate('validations.minValue'),
        }),
        maxValue: withI18nMessage(validators.maxValue, {
            withArguments: true,
            messagePath: translate('validations.maxValue'),
        }),
        email: withI18nMessage(validators.email, {
            messagePath: translate('validations.email'),
        }),
        shouldBeKeyExpr: withI18nMessage(shouldBeKeyexpr, {
            messagePath: translate('validations.shouldBeKeyExpr'),
        }),
        sameAs: validators.sameAs,
        // shouldContainsLowerCase: withI18nMessage(shouldContainsLowerCase, {
        //     messagePath: translate('validations.shouldContainsLowercase'),
        // })
        // shouldContainsUpperCase: withI18nMessage(shouldMatch(/[A-Z]+/), {
        //     messagePath: translate('validations.shouldContainsUppercase'),
        // }),
        // shouldContainsDigit: withI18nMessage(shouldMatch(/[0-9]+/), {
        //     messagePath: translate('validations.shouldContainsUppercase'),
        // }),
        // shouldContainsSpecialChar: withI18nMessage(
        //     shouldMatch(/[&@#(!)-_=+:/;.,]+/),
        //     {
        //         messagePath: translate('validations.shouldContainsSpecialChar'),
        //     }
        // ),
    };
};
