import { helpers } from '@vuelidate/validators';

const shouldBeSignedBy = (
    projectId: string,
    authority: MaybeRef<TX509Document> | string
) => {
    return helpers.withParams(
        { type: 'shouldBeSignedBy', authority },
        helpers.withAsync(async (value: TX509Document) => {
            const fn = usex509CertAuthValidator();

            return await fn(projectId, authority, value);
        })
    );
};

export default shouldBeSignedBy;
