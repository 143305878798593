<template>
    <v-form
        ref="form"
        @click.stop="() => {}"
        @submit.prevent="submit">
        <v-row>
            <v-col
                cols="12"
                sm="6">
                <app-text-field
                    v-model="model.name"
                    class="py-2"
                    color="primary"
                    :label="$t('labels.nameField')"
                    :error-messages="
                        v$.name.$errors.map((e) => unref(e.$message))
                    "
                    hide-details="auto"></app-text-field>

                <app-text-field
                    v-model="model.company"
                    class="py-2"
                    color="primary"
                    :error-messages="
                        v$.company.$errors.map((e) => unref(e.$message))
                    "
                    :label="$t('labels.companyField')"
                    hide-details="auto"></app-text-field>

                <app-text-field
                    v-model="model.email"
                    class="py-2"
                    color="primary"
                    :error-messages="
                        v$.email.$errors.map((e) => unref(e.$message))
                    "
                    validate-on="blur"
                    hide-details="auto">
                    <template #label>
                        {{ $t('labels.emailField') }}
                    </template>
                </app-text-field>

                <app-textarea
                    v-model="model.message"
                    class="py-2 mb-2 mb-md-0"
                    :error-messages="
                        v$.message.$errors.map((e) => unref(e.$message))
                    "
                    :label="$t('labels.messageField')"
                    hide-details="auto" />
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-checkbox
                    v-model="model.privacyPolicyRead"
                    color="primary"
                    :error-messages="
                        v$.privacyPolicyRead.$errors.map((e) =>
                            unref(e.$message)
                        )
                    ">
                    <template #label>
                        <span class="text-body-2">{{
                            $t('labels.privacyPolicyRead')
                        }}</span>
                    </template>
                </v-checkbox>
                <v-checkbox
                    v-model="model.subscribeNewsletter"
                    color="primary"
                    :error-messages="
                        v$.subscribeNewsletter.$errors.map((e) =>
                            unref(e.$message)
                        )
                    ">
                    <template #label>
                        <span class="text-body-2">{{
                            $t('labels.subscribeNewsletter')
                        }}</span>
                    </template>
                </v-checkbox>
                <v-spacer />
                <div class="ml-2 d-flex justify-space-evenly justify-sm-start">
                    <app-primary-btn type="submit">
                        {{ $t('buttons.sendMessage') }}
                    </app-primary-btn>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>
<script setup lang="ts">
import useVuelidate from '@vuelidate/core';

const { shouldBeChecked, email, required } = useValidators();

const model = ref<TContactForm>({
    name: '',
    company: '',
    email: '',
    message: '',
    privacyPolicyRead: false,
    subscribeNewsletter: false,
});
const rules = {
    name: { required },
    company: [],
    email: { required, email },
    message: { required },
    privacyPolicyRead: { shouldBeChecked },
    subscribeNewsletter: [],
};

type TEmits = {
    (e: 'submit', form: TContactForm): void;
};

const emits$ = defineEmits<TEmits>();

const form = ref();
const v$ = useVuelidate(rules, model);

async function submit() {
    const isValid = await v$.value.$validate();

    if (isValid) {
        emits$('submit', model.value);
    }
}
</script>
