import type { ThemeDefinition } from 'vuetify/lib/framework.mjs';

export default {
    dark: false,
    colors: {
        appbar: '#0A143C',
        background: '#F3F3F5', // Adjusted
        'background-secondary': '#FFF', // Adjusted
        primary: '#14b4ff',
        'virtual-edge-color': '#FFAAFF',
        'project-edge-color': '#1FF5FF',
        'secured-edge-color': '#14b4ff',
        'unsecured-edge-color': '#1433ff',

        secondary: '#000', // Adjusted
        info: '#00FF',
        error: '#F00', // Adjusted
        'on-primary': '#e9e5d0', // Adjusted
        'on-background': '#02040D', // Adjusted
        // 'on-surface': '#D52C',
        'on-secondary': '#ABFD',
        'on-success': '#02040D',
        'on-warning': '#02040D',
        'on-error': '#02040D', // Adjusted
        'on-info': '#7FCD',
        'header-ext-bg': '#AAA',
    },
} as ThemeDefinition;
