<template>
    <v-list
        density="compact"
        class="mt-2 user-actions-overlay">
        <v-list-item
            to="/user/profile"
            :active="false"
            @click="$emit('close')">
            <div class="d-flex flex-column align-center justify-center pa-4">
                <user-gravatar
                    :email="email"
                    class="elevation-15 mb-4" />
                <span> {{ username }}</span>
                <span> {{ email }}</span>
            </div>
        </v-list-item>

        <demo-content>
            <v-list-item
                class="hover-effect upgrade-button"
                to="/organisation/upgrade"
                @click="$emit('close')">
                <template #title>
                    <span>{{ $t('buttons.upgrade') }}</span>
                </template>

                <template
                    v-if="daysLeft !== undefined"
                    #subtitle>
                    <span class="text-high-emphasis pt-1">{{
                        $t('buttons.freeTrialTimeLeft', {
                            days: daysLeft,
                        })
                    }}</span>
                </template>

                <template #prepend>
                    <v-icon color="warning">
                        mdi-shield-lock-open-outline
                    </v-icon>
                </template>
            </v-list-item>
        </demo-content>

        <v-list-group
            value="org"
            class="ml-0">
            <template #activator="{ props }">
                <v-list-item
                    class="hover-effect"
                    prepend-icon="$domain"
                    v-bind="props">
                    <template #title>{{ $t('titles.organisation') }} </template>
                </v-list-item>
            </template>
            <v-list-item
                class="hover-effect"
                :to="{ name: 'user-organisation' }"
                @click="$emit('close')">
                <template #title>
                    <span>{{ $t('labels.edit') }}</span>
                </template>
            </v-list-item>

            <demo-or-cc-content>
                <v-list-item
                    class="hover-effect"
                    :to="{ name: 'organisation-payments' }"
                    @click="$emit('close')">
                    <template #title>
                        <span>{{ $t('titles.organisation_payments') }}</span>
                    </template>
                </v-list-item>
            </demo-or-cc-content>

            <v-list-item
                class="hover-effect"
                :to="{ name: 'organisation-billing' }">
                <template #title>
                    <span>{{ $t('titles.billing') }}</span>
                </template>
            </v-list-item>
        </v-list-group>

        <v-list-item
            v-if="false"
            :title="$t('languages')"
            class="hover-effect"
            prepend-icon="$earth"
            @click="openLanguageSelector"></v-list-item>

        <v-list-item
            :title="$t('titles.platform_pricing')"
            class="hover-effect"
            prepend-icon="mdi-cash"
            to="/pricing" />

        <v-list-group
            value="theme"
            class="ml-0">
            <template #activator="{ props }">
                <v-list-item
                    class="hover-effect"
                    v-bind="props"
                    prepend-icon="$theme">
                    <template #title>
                        <span>{{ $t('themes.title') }}</span>
                    </template>
                </v-list-item>
            </template>
            <v-list-item
                v-for="(_def, themeKey) of themes"
                :key="`theme-${_def.variables.name}`"
                :active="currentName === themeKey"
                class="hover-effect"
                @click="setTheme(themeKey)">
                <template #title>
                    <span class="text-capitalize">{{
                        _def.variables.name || themeKey
                    }}</span>
                </template>
            </v-list-item>
        </v-list-group>

        <v-list-item
            class="hover-effect"
            prepend-icon="$logout"
            to="/logout"
            @click="$emit('close')">
            <template #title>
                <span>{{ $t('buttons.logout') }}</span>
            </template>
        </v-list-item>
    </v-list>
    <app-language-selector-modal
        v-model="openModal"></app-language-selector-modal>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { useTheme } from 'vuetify';

const openModal = ref(false);
type TProps = {
    username: string;
    email: string;
};
defineProps<TProps>();
type TEmits = {
    (e: 'close'): void;
};
defineEmits<TEmits>();
const vuetifyTheme = useTheme();
const currentName = computed(() => vuetifyTheme.global.name.value);
const themes = computed(() => vuetifyTheme.themes.value);

function openLanguageSelector() {
    openModal.value = true;
}

function setTheme(theme: string) {
    useSettingsStore().useTheme(theme);
}
const organisationsStore = useOrganisationsStore();
const { organisations } = storeToRefs(organisationsStore);

if (!organisations.value.length) {
    organisationsStore.fetchOrganisations();
}

const org = computed(() => organisations.value.at(0));

const daysLeft = computed(() => {
    if (!org.value?.expirationDate) return undefined;

    const endDate = DateTime.fromISO(org.value.expirationDate);
    const today = DateTime.now();
    const dateDiff = Math.floor(endDate.diff(today, 'days').days);

    return Math.max(0, dateDiff);
});
</script>

<style lang="scss" scoped>
.continuum--dark {
    .upgrade-button {
        background-color: rgb(var(--v-theme-warning), 0.05);

        .v-list-item-subtitle > span {
            color: #ffa94a !important;
        }

        &.v-list-item--active * {
            color: rgb(var(--v-theme-on-background-secondary));
        }
    }
}

.continuum {
    .upgrade-button {
        background-color: rgb(var(--v-theme-warning), 0.05);

        &.v-list-item--active * {
            color: rgb(var(--v-theme-on-background-secondary));
        }
    }
}

.user-actions-overlay * {
    font-size: 0.875rem;
}
</style>
