<template>
    <v-footer
        ref="footer"
        app
        class="flex-column py-1 border-t"
        color="background">
        <v-expand-transition mode="in-out">
            <div
                v-if="isDeployed"
                class="d-flex justify-center mb-2 flex-column"
                style="width: 75vw; max-width: 1024px">
                <div class="d-flex text-h4 text-secondary">
                    {{ $t('titles.contactUs') }}
                </div>
                <div class="d-flex flex-row">
                    <div class="mx-2">
                        <contact-form @submit="performSend" />
                    </div>

                    <div class="d-none d-md-flex mx-2">
                        <zetta-contact />
                    </div>
                </div>
            </div>
        </v-expand-transition>

        <div class="text-caption">
            {{ $t('trademark1', { year: DateTime.now().year }) }}
            -
            <nuxt-link
                to="/terms-and-conditions"
                target="_blank">
                {{ $t('titles.termsAndConditions') }}
            </nuxt-link>
            -

            <a
                class="cursor-pointer"
                :href="'mailTo:' + contactUsEmail">
                {{ $t('titles.contactUs') }}</a
            >

            -
            <v-tooltip>
                <template #activator="slotProps">
                    <span v-bind="slotProps.props">{{ version }}</span>
                </template>
                {{ sha1 }}
            </v-tooltip>
        </div>
    </v-footer>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';

const version = useAppVersion();

const footer = ref();

const isDeployed = ref(false);
const contactUsEmail = useAppConfig().contactUs.email;
const sha1 = import.meta.env.GITHUB_SHA || 'None';

/* function toggle() {
    isDeployed.value = !isDeployed.value;
}
*/
async function performSend(form: TContactForm) {
    await useFetch('http://www.zettascale.tech/api/send-newsletter', {
        body: {
            name: form.name,
            email: form.email,
            company: form.company,
            message: form.message,
            check1: form.privacyPolicyRead,
            check2: form.subscribeNewsletter,
        },
        method: 'POST',
    });
}
</script>
<style lang="scss" scoped>
.border-top {
    width: 50%;
    border-top: 1px solid rgb(var(--v-theme-primary));
    filter: drop-shadow(0px 0px 4px rgba(var(--v-theme-primary), 0.9))
        drop-shadow(0px 0px 10px rgba(var(--v-theme-primary), 0.8))
        drop-shadow(0px 0px 4px rgba(var(--v-theme-primary), 0.2));
}

footer {
    height: auto;
    cursor: pointer;
}
</style>
