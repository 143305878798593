import { POSITION, TYPE, useToast } from 'vue-toastification';
import type { ToastOptions } from 'vue-toastification/dist/types/types';

export default defineStore('notifications', () => {
    const toast = useToast();

    function notify(
        message: string,
        type: 'Info' | 'Error' | 'Success' | 'Warning' = 'Info',
        timeout = 4750
    ) {
        const options: ToastOptions = {
            timeout,
            position: POSITION.BOTTOM_RIGHT,
            showCloseButtonOnHover: true,
        };

        switch (type) {
            case 'Error':
                toast.error(message, { ...options, type: TYPE.ERROR });
                break;
            case 'Info':
                toast.info(message, { ...options, type: TYPE.INFO });
                break;
            case 'Success':
                toast.success(message, { ...options, type: TYPE.SUCCESS });
                break;
            case 'Warning':
                toast.warning(message, { ...options, type: TYPE.WARNING });
                break;
        }
    }

    return {
        notify,
        error: (msg: string, timeout = 2000) => notify(msg, 'Error', timeout),
        info: (msg: string, timeout = 2000) => notify(msg, 'Info', timeout),
        success: (msg: string, timeout = 2000) =>
            notify(msg, 'Success', timeout),
        warn: (msg: string, timeout = 2000) => notify(msg, 'Warning', timeout),
    };
});
