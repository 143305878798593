<template>
    <v-app>
        <nuxt-layout>
            <nuxt-page key="/" />
        </nuxt-layout>
    </v-app>
    <!-- <CookieManager /> -->
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useTheme } from 'vuetify';

const t = useTheme();

const { theme } = storeToRefs(useSettingsStore());
watchEffect(() => {
    t.global.name.value = theme.value;
    if (!t.current.value.dark) {
        document.body.classList.remove('continuum--dark');
    } else {
        document.body.classList.add('continuum--dark');
    }
});

const bgImg = ref('zetta_dark');
const route = useRoute();

onMounted(() => {
    document.body.className = 'continuum';

    if (t.current.value.dark) {
        document.body.classList.add('continuum--dark');
    }
});
watchEffect(() => {
    bgImg.value = route.meta.logo?.toString() || 'zetta_dark';
});
</script>
<style lang="scss">
.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}

.continuum-paas .bg {
    position: absolute;
    width: 100vw;
    height: 100vh;

    img {
        position: absolute;
        bottom: 80px;
        right: 45px;
        width: 160px;
        filter: drop-shadow(5px 5px 1px rgba(var(--v-theme-primary), 0.7));
    }
}
.v-theme--light {
    .continuum-paas .bg {
        img {
            opacity: 0.65;
        }
    }
}
.v-theme--dark {
    .continuum-paas .bg {
        img {
            filter: invert(60%)
                drop-shadow(5px 5px 3px rgba(var(--v-theme-primary), 0.9))
                drop-shadow(5px 5px 5px rgba(var(--v-theme-primary), 1));
            opacity: 0.3;
        }
    }
}
.v-theme--grey {
    .continuum-paas .bg {
        img {
            filter: invert(80%)
                drop-shadow(5px 5px 3px rgba(var(--v-theme-primary), 0.9))
                drop-shadow(5px 5px 5px rgba(var(--v-theme-primary), 1));
            opacity: 0.3;
        }
    }
}
.v-theme--fulldark {
    .continuum-paas .bg {
        img {
            filter: invert(55%)
                drop-shadow(5px 5px 3px rgba(var(--v-theme-primary), 0.9))
                drop-shadow(5px 5px 5px rgba(var(--v-theme-primary), 1));

            opacity: 0.5;
        }
    }
}
</style>
