<template>
    <app-modal
        v-model="model"
        width="400px">
        <template #title>
            <slot name="title">
                {{ $t('titles.changeLanguage') }}
            </slot>
        </template>
        <template #default>
            <slot name="default">
                <div
                    class="d-flex flex-column gap-2 flex-wrap language-container overflow-auto">
                    <span
                        v-for="language in locales"
                        :key="language.code"
                        class="hover-effect d-flex gap-2 pa-2"
                        :class="{
                            selected: language.code === currentLanguage.code,
                        }"
                        @click="$i18n.setLocale(language.code)">
                        <icon :name="language.flag" />
                        {{ language.name }}
                    </span>
                </div>
            </slot>
        </template>
        <template #actions>
            <app-primary-btn
                class="mr-2"
                @click="model = false">
                {{ $t('buttons.close') }}
            </app-primary-btn>
        </template>
    </app-modal>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables';

const model = defineModel<any>({
    required: true,
});

const { locale, locales: locales$ } = useI18n();
const locales = locales$ as Ref<LocaleObject[]>;

const currentLanguage = ref<LocaleObject>(
    locales.value!.find((l) => l.code === locale.value)!
);
</script>
<style lang="scss" scoped>
.language-container {
    span {
        flex: 100% 0 0;

        &.selected {
            background-color: rgba(
                var(--v-theme-primary),
                var(--v-selected-opacity)
            ) !important;
            color: rgb(var(--v-theme-primary));
        }
    }
}
</style>
