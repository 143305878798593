import { helpers } from '@vuelidate/validators';
import * as x509 from '@peculiar/x509';

function shouldBeX509(value: TX509Document) {
    if (!helpers.req(value)) {
        return true;
    }

    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _a = new x509.X509Certificate(value.content);
        return true;
    } catch (e) {
        return false;
    }
}

export default helpers.withParams({ type: 'shouldBeX509' }, shouldBeX509);
