export default () => {
    function randomItemFrom(src: string[]): string {
        return src[Math.floor(Math.random() * src.length)];
    }

    function generateRandomName() {
        const appConfig = useAppConfig();

        return (
            randomItemFrom(appConfig.designSystem.namingLists.left) +
            ' ' +
            randomItemFrom(appConfig.designSystem.namingLists.right)
        );
    }

    return generateRandomName;
};
