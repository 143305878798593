import { createVuetify } from 'vuetify';
import light from '../themes/light';
import dark from '../themes/dark';
import { aliases as zetIcons, zet } from '../iconsets/zetIcons';
import { mdi, aliases } from 'vuetify/iconsets/mdi';

export default defineNuxtPlugin((nuxtApp) => {
    const vuetify = createVuetify({
        ssr: true,
        theme: {
            defaultTheme: 'dark',
            themes: {
                light,
                dark,
            },
            variations: {
                colors: ['primary', 'secondary'],
                lighten: 3,
                darken: 3,
            },
        },
        locale: {
            locale: 'en',
        },
        icons: {
            aliases: {
                ...aliases,
                ...zetIcons,
            },
            sets: {
                mdi,
                zet,
            },
        },
    });

    nuxtApp.vueApp.use(vuetify);
});
