import { helpers } from '@vuelidate/validators';

function shouldOpenCertificate(certFieldName: string) {
    return async (value: TX509Document, siblings: any) => {
        const response = await useFetch('/api/utils/certs/validate-key', {
            body: {
                key: value.content,
                cert: siblings[certFieldName]?.content,
            },
            method: 'POST',
        });
        return response.data.value?.isValid === true;
    };
}

export default (certFieldName: string) =>
    helpers.withAsync(shouldOpenCertificate(certFieldName));
