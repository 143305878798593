import Toast from 'vue-toastification';

export default defineNuxtPlugin((nuxtApp) => {
    const options = {
        transition: 'Vue-Toastification__bounce',
        maxToasts: 20,
        newestOnTop: true,

        // You can set your default options here
    };

    nuxtApp.vueApp.use(Toast, options);
});
