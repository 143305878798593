import { DateTime } from 'luxon';
import type { PiniaPluginContext } from 'pinia';

const storeOptions = {
    persist: {
        paths: ['billingElements', 'lastUpdate'],
        storage: persistedState.localStorage,
        afterRestore: (ctx: PiniaPluginContext) => {
            const now = DateTime.fromObject({
                year: DateTime.now().year,
                month: DateTime.now().month,
            });

            const runtimeConfig = useRuntimeConfig();

            Object.keys(ctx.store.billingElements).forEach((year) => {
                Object.keys(ctx.store.billingElements[year]).forEach(
                    (month) => {
                        const t = DateTime.fromObject({
                            year: Number.parseInt(year),
                            month: Number.parseInt(month),
                        });

                        if (
                            t <
                            now.minus({
                                months: runtimeConfig.public.billing
                                    .historySize,
                            })
                        ) {
                            delete ctx.store.billingElements[year][month];
                        }
                    }
                );
                if (
                    !Object.values(ctx.store.billingElements[year]).filter(
                        (x) => !!x
                    ).length
                ) {
                    delete ctx.store.billingElements[year];
                }
            });
        },
    },
};

export default (target: TOrganisation) => {
    return defineStore(
        'org-' + target.id,
        () => {
            const organisation = ref(target);
            const billingElements = reactive<{
                [year: number]: {
                    [month: number]: {
                        lastUpdate?: number;
                        data: TBillingElement[];
                    };
                };
            }>({});

            async function update(org: TOrganisation) {
                await $fetch(`/api/organisations/${target.id}`, {
                    method: 'POST',
                    body: {
                        org: {
                            ...org,
                            id: target.id,
                        },
                    },
                    mode: 'same-origin',
                    cache: 'no-cache',
                    watch: false,
                    baseURL: useRuntimeConfig().public.apiBase,
                });

                organisation.value = org;
            }

            async function fetchBillingElements(date: MaybeRef<DateTime>) {
                const { year, month } = unref(date);
                if (!billingElements[year]) {
                    billingElements[year] = {};
                }

                const maxHistoryDate = DateTime.now().minus({
                    minutes: useRuntimeConfig().public.billing.refreshInterval,
                });

                if (!billingElements[year][month]) {
                    billingElements[year][month] = {
                        lastUpdate: undefined,
                        data: [],
                    };
                }
                if (
                    !billingElements[year][month].lastUpdate ||
                    DateTime.fromMillis(
                        billingElements[year][month].lastUpdate
                    ) < maxHistoryDate
                ) {
                    billingElements[year][month].lastUpdate =
                        DateTime.now().toMillis();

                    const { data, error } = await useAsyncData(
                        `${target.id}-billing-items-${month}-${year}`,
                        async () =>
                            await $fetch(
                                `/api/organisations/${target.id}/billing?year=${year}&month=${month}`
                            )
                    );

                    if (error.value) {
                        useNotificationsStore().error(
                            `Cannot fetch billing informations for ${month}/${year}.`
                        );
                    }
                    billingElements[year][month].data = data.value || [];
                }
            }

            return {
                organisation,
                billingElements,
                update,
                fetchBillingElements,
            };
        },
        storeOptions
    );
};
