import { helpers } from '@vuelidate/validators';

async function shouldBeAvailableDomain(value: string) {
    if (!helpers.req(value)) {
        return true;
    }

    const response = await fetch(
        `/api/utils/domain/isAvailable?domain=${value}`
    );
    const json = await response.json();

    return json.isAvailable === true;
}

export default helpers.withAsync(
    helpers.withParams(
        {
            type: 'shouldBeAvailableDomain',
        },
        shouldBeAvailableDomain
    )
);
