
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "edca3476-9de6-4e40-a367-cec615e1515f"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/src/nuxt-app/app.config.ts"
import cfg1 from "/usr/src/nuxt-app/layers/design-system/app.config.ts"
import cfg2 from "/usr/src/nuxt-app/layers/vuetify-layer/app.config.ts"
import cfg3 from "/usr/src/nuxt-app/layers/registration/app.config.ts"
import cfg4 from "/usr/src/nuxt-app/layers/shared/app.config.ts"
import cfg5 from "/usr/src/nuxt-app/layers/types/app.config.ts"
import cfg6 from "/usr/src/nuxt-app/layers/auth/app.config.ts"
import cfg7 from "/usr/src/nuxt-app/layers/metrics/app.config.ts"
import cfg8 from "/usr/src/nuxt-app/layers/highcharts/app.config.ts"
import cfg9 from "/usr/src/nuxt-app/layers/design-system/app.config.ts"
import cfg10 from "/usr/src/nuxt-app/layers/vuetify-layer/app.config.ts"
import cfg11 from "/usr/src/nuxt-app/layers/dashboards/app.config.ts"
import cfg12 from "/usr/src/nuxt-app/layers/design-system/app.config.ts"
import cfg13 from "/usr/src/nuxt-app/layers/vuetify-layer/app.config.ts"
import cfg14 from "/usr/src/nuxt-app/layers/shared/app.config.ts"
import cfg15 from "/usr/src/nuxt-app/layers/types/app.config.ts"
import cfg16 from "/usr/src/nuxt-app/layers/dds/app.config.ts"
import cfg17 from "/usr/src/nuxt-app/layers/design-system/app.config.ts"
import cfg18 from "/usr/src/nuxt-app/layers/vuetify-layer/app.config.ts"
import cfg19 from "/usr/src/nuxt-app/layers/shared/app.config.ts"
import cfg20 from "/usr/src/nuxt-app/layers/types/app.config.ts"
import cfg21 from "/usr/src/nuxt-app/layers/dds-qos/app.config.ts"
import cfg22 from "/usr/src/nuxt-app/layers/design-system/app.config.ts"
import cfg23 from "/usr/src/nuxt-app/layers/vuetify-layer/app.config.ts"
import cfg24 from "/usr/src/nuxt-app/layers/types/app.config.ts"
import cfg25 from "/usr/src/nuxt-app/layers/shared/app.config.ts"
import cfg26 from "/usr/src/nuxt-app/layers/types/app.config.ts"
import cfg27 from "/usr/src/nuxt-app/layers/types/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, cfg7, cfg8, cfg9, cfg10, cfg11, cfg12, cfg13, cfg14, cfg15, cfg16, cfg17, cfg18, cfg19, cfg20, cfg21, cfg22, cfg23, cfg24, cfg25, cfg26, cfg27, inlineConfig)
