<template>
    <base-error>
        <div class="d-flex flex-column flex-md-row align-md-center">
            <div class="">
                <v-img
                    src="/glitch.png"
                    width="400"
                    max-width="75vw"
                    max-height="50vh" />
            </div>

            <div
                class="d-flex justify-center pt-10 pt-md-0 flex-column pl-10"
                style="max-width: 500px">
                <div class="d-none d-md-block text-h3 text-primary pb-4">
                    {{ $t('into_rabbit_hole') }}
                </div>
                <div
                    class="text-h6 text-secondary text-center text-md-left"
                    style="max-width: 400px">
                    {{ error || $t('unknown_error') }}
                </div>
                <div class="text-secondary">
                    <a
                        class="cursor-pointer"
                        :href="'mailTo:' + contactUsEmail">
                        {{ $t('titles.contactUs') }}</a
                    >
                </div>
            </div>
        </div>
    </base-error>
</template>
<script setup lang="ts">
import BaseError from './BaseError.vue';

type TProps = {
    error?: string;
};
defineProps<TProps>();

const contactUsEmail = useAppConfig().contactUs.email;
</script>
<style lang="scss">
.neon {
    $color: var(--v-theme-primary-darken-1);
    filter: drop-shadow(0px 0px 4px rgba($color, 0.9))
        drop-shadow(0px 0px 10px rgba($color, 0.8))
        drop-shadow(0px 0px 4px rgba($color, 0.2));
}
</style>
