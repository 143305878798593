<template>
    <div>
        <div class="mb-4 d-flex flex-column">
            <div class="font-weight-bold subtitle-1 mb-4">
                <slot name="title" />
            </div>
            <div>
                <div class="subtitle-2 font-weight-bold mb-2">
                    <slot name="subtitle" />
                </div>
                <div class="flex-column d-flex">
                    <slot name="default" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.subtitle-2 {
    color: rgb(var(--v-theme-secondary));
}
</style>
