import { OrgKind } from '@ZettaScaleLabs/continuum-node-client/dist/organization';

export default defineNuxtRouteMiddleware(async (to) => {
    const organisationsStore = useOrganisationsStore();
    const { organisations } = storeToRefs(organisationsStore);

    if (!organisations.value.length) {
        await organisationsStore.fetchOrganisations();
    }

    const org = organisations.value.at(0);

    if (to.meta.auth === false || to.meta.allowLocked) {
        return;
    }

    if (!!org && org.lock) {
        // Not DEMO => ContactUs
        // Else
        if (org.kind === OrgKind.DEMO) {
            return navigateTo('/organisation/upgrade');
        } else {
            abortNavigation('User locked');
        }
    }
});
