<template>
    <v-app>
        <v-layout>
            <app-header
                :display-menu="false"
                minimal />
            <v-main>
                <div class="d-flex flex-column h-100 justify-center">
                    <div class="flex-grow-1 d-flex align-center justify-center">
                        <div class="w-75 h-100">
                            <component
                                :is="component"
                                :error="error" />
                        </div>
                    </div>
                </div>
            </v-main>
            <app-footer />
        </v-layout>
    </v-app>
</template>
<script setup lang="ts">
import NotFound from '~/components/errors/NotFound.vue';
import UnknownError from '~/components/errors/UnknownError.vue';

type TProps = {
    error: { statusCode: number };
};
const props = defineProps<TProps>();
const component = computed(() => {
    if (props.error.statusCode === 404) {
        return NotFound;
    }
    return UnknownError;
});
</script>
