export default defineStore('organisations', () => {
    const elements = ref<TOrganisation[]>([]);

    async function fetchOrganisations() {
        const orgs = await useFetch('/api/organisations', {
            cache: 'no-store',
            baseURL: useRuntimeConfig().public.apiBase,
        });

        if (!orgs.error.value && orgs.data.value) {
            elements.value = orgs.data.value;
            elements.value.forEach((org) => useOrganisationStore(org)());
        }
    }

    async function updateOrganisation(org: TOrganisation) {
        await useOrganisationStore(org)().update(org);

        elements.value = elements.value.filter((e) => e.id !== org.id);
        elements.value.push(org);
    }

    return {
        organisations: elements,
        updateOrganisation,
        fetchOrganisations,
    };
});
