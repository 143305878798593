<template>
    <div class="d-flex flex-column justify-space-between">
        <ContactSection>
            <template #title>
                <span>{{ $t('zettaContactAddressLabel') }}</span>
            </template>
            <template #subtitle>ZETTASCALE TECHNOLOGY</template>
            <span class="text-body-2">Les Algorithmes - 91190</span>
            <span class="text-body-2">Saint Aubain - France</span>
            <span class="text-body-2">
                <a :href="`mailto:${socials.email}`">
                    {{ socials.email }}
                </a>
            </span>
        </ContactSection>
        <ContactSection class="join-us d-none d-md-flex">
            <template #title>{{ $t('zettaContactJoinUsLabel') }}</template>
            <a
                :href="socials.twitter"
                target="_blank">
                <v-icon
                    icon="mdi-twitter"
                    class="mr-2 mb-1"
                    color="primary" />Twitter
            </a>

            <a
                :href="socials.github"
                target="_blank">
                <v-icon
                    icon="mdi-github"
                    class="mr-2 mb-1"
                    color="white" />Github
            </a>

            <a
                :href="socials.discord"
                target="_blank">
                <nuxt-icon
                    name="discord"
                    style="height: 1em"
                    filled
                    class="ml-1 mr-2 mb-1"></nuxt-icon>
                Discord
            </a>
        </ContactSection>
    </div>
</template>

<script setup lang="ts">
const socials = useAppConfig().socials;
</script>

<style lang="scss">
.join-us {
    a {
        text-decoration: none;
        color: rgb(var(--v-theme-secondary));
        transition: color 0.2s ease;

        &:visited {
            color: rgb(var(--v-theme-secondary));
        }

        &:hover {
            color: rgb(var(--v-theme-primary));
        }
    }

    .nuxt-icon svg {
        margin-bottom: 0;
    }
}
</style>
