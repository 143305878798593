<!-- eslint-disable vue/require-explicit-emits -->
<template>
    <v-text-field
        ref="field"
        density="compact"
        color="primary"
        class="app-text-field"
        v-bind="$attrs">
        <template
            v-if="$slots.append || allowRandom"
            #append>
            <v-icon
                v-if="allowRandom"
                size="small"
                icon="$refresh"
                @click="$emit('update:modelValue', generateName())" />
            <slot name="append" />
        </template>
    </v-text-field>
</template>
<script setup lang="ts">
type TProps = {
    allowRandom?: boolean;
};
withDefaults(defineProps<TProps>(), {
    allowRandom: false,
});
const field = ref();

defineExpose({
    field,
});
const generateName = useRandomName();
</script>
<style>
.app-text-field .v-field {
    font-size: 0.8rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
    font-family: 'Epilogue', serif !important;
    text-transform: none !important;
}
</style>
