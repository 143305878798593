import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as loginlzkgvgJgWgMeta } from "/usr/src/nuxt-app/pages/login.vue?macro=true";
import { default as logoutPCUZmFcBWgMeta } from "/usr/src/nuxt-app/pages/logout.vue?macro=true";
import { default as not_45availableiAmPBLu0HyMeta } from "/usr/src/nuxt-app/pages/not-available.vue?macro=true";
import { default as billingRAOFT88ONgMeta } from "/usr/src/nuxt-app/pages/organisation/billing.vue?macro=true";
import { default as paymentskX1ZNGVSKoMeta } from "/usr/src/nuxt-app/pages/organisation/payments.vue?macro=true";
import { default as upgradeFXb4Abtnn3Meta } from "/usr/src/nuxt-app/pages/organisation/upgrade.vue?macro=true";
import { default as organisationR0Nx3BUBjAMeta } from "/usr/src/nuxt-app/pages/organisation.vue?macro=true";
import { default as playgroundtQghnXVMeBMeta } from "/usr/src/nuxt-app/pages/playground.vue?macro=true";
import { default as pricingO3tVJMzC74Meta } from "/usr/src/nuxt-app/pages/pricing.vue?macro=true";
import { default as clientsaoDWpP8ly2Meta } from "/usr/src/nuxt-app/pages/projects/[projectId]/clients.vue?macro=true";
import { default as _91dashboardId_93MJYUgjEwssMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/dashboards/[dashboardId].vue?macro=true";
import { default as dashboardsqeenRq8thYMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/dashboards.vue?macro=true";
import { default as indexcQjuiTOdweMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/index.vue?macro=true";
import { default as indexjyUG6874tUMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/index.vue?macro=true";
import { default as indexxco4FmmY5AMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/readers/[readerId]/index.vue?macro=true";
import { default as indexBxOv6kwNz1Meta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/readers/index.vue?macro=true";
import { default as readersKa0B3QIggvMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/readers.vue?macro=true";
import { default as indexEuiILiVXpLMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/readers/[readerId]/index.vue?macro=true";
import { default as indexvdHnr7a5YrMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/readers/index.vue?macro=true";
import { default as readersk7pYM88WwMMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/readers.vue?macro=true";
import { default as indexdqWNurvJArMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/writers/[writerId]/index.vue?macro=true";
import { default as indexoomVgDw8A8Meta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/writers/index.vue?macro=true";
import { default as writerstPXC1NVzFRMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/writers.vue?macro=true";
import { default as _91topicName_93XWlK2jdkMzMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName].vue?macro=true";
import { default as indexHcXJ065VLpMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/index.vue?macro=true";
import { default as topicsObsQPsa1gbMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics.vue?macro=true";
import { default as indexO2HKoGNfAeMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/writers/[writerId]/index.vue?macro=true";
import { default as indexWJPXohpUOOMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/writers/index.vue?macro=true";
import { default as writers3RnZqq4VUXMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/writers.vue?macro=true";
import { default as _91participantId_93VzUUEOyIbbMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId].vue?macro=true";
import { default as index7M7p4ENrtJMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/index.vue?macro=true";
import { default as participantsn7g1H1TUKSMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants.vue?macro=true";
import { default as indexVUcP8nD9fhMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/index.vue?macro=true";
import { default as indexvgy14xMfKnMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/index.vue?macro=true";
import { default as index0vcIbacyyAMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/readers/[readerId]/index.vue?macro=true";
import { default as indexee7CzBquAOMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/readers/index.vue?macro=true";
import { default as readersVUONE0DmxvMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/readers.vue?macro=true";
import { default as indexnJckgPyqVMMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/writers/[writerId]/index.vue?macro=true";
import { default as indexXGUkAfAfLiMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/writers/index.vue?macro=true";
import { default as writersXMla5wmOuiMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/writers.vue?macro=true";
import { default as _91participantId_933QtwzjeSPxMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId].vue?macro=true";
import { default as index1wH40pkkbiMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/index.vue?macro=true";
import { default as participantsOZnwzu8cxjMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants.vue?macro=true";
import { default as indexAZOdXX4QwvMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/readers/[readerId]/index.vue?macro=true";
import { default as indexEKrmGrZg90Meta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/readers/index.vue?macro=true";
import { default as readersWVZeUyVqPGMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/readers.vue?macro=true";
import { default as indexS63f8ozO0gMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/writers/[writerId]/index.vue?macro=true";
import { default as index5bdWQFDzvGMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/writers/index.vue?macro=true";
import { default as writersdvRmcOHOYmMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/writers.vue?macro=true";
import { default as _91topicName_93bMpHWgS0qFMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName].vue?macro=true";
import { default as indexa3cJwSaPtlMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/index.vue?macro=true";
import { default as topicsNHANz8PPcsMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics.vue?macro=true";
import { default as _91domainId_93JbQIFcPyfqMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId].vue?macro=true";
import { default as indexhqJYHpsq04Meta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/index.vue?macro=true";
import { default as listyiFvakcwxKMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/list.vue?macro=true";
import { default as domainslhd3A1Y7oOMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains.vue?macro=true";
import { default as indextrIBHnp1wRMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/index.vue?macro=true";
import { default as ddsC0UbNdWmNBMeta } from "/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds.vue?macro=true";
import { default as edit26PNnDK7sbMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/edit.vue?macro=true";
import { default as indexef9lNezxV7Meta } from "/usr/src/nuxt-app/pages/projects/[projectId]/index.vue?macro=true";
import { default as maplJdJQQdPFFMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/map.vue?macro=true";
import { default as metricsehLqZ2GIPAMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/metrics.vue?macro=true";
import { default as indexVddh4X5fQCMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/index.vue?macro=true";
import { default as infoYT4Z2fY6BhMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/info.vue?macro=true";
import { default as metricsfawFhpJezEMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/metrics.vue?macro=true";
import { default as pluginsCiz41ZtC44Meta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/plugins.vue?macro=true";
import { default as storagesZmMwvPFi4hMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/storages.vue?macro=true";
import { default as _91routerId_93uSD1tTDAgdMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId].vue?macro=true";
import { default as indexsg0BzHEP56Meta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/index.vue?macro=true";
import { default as listKKLRbYBocxMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers/list.vue?macro=true";
import { default as routersJmcPZtsUpEMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/routers.vue?macro=true";
import { default as indexQMDokDA4S7Meta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/app/index.vue?macro=true";
import { default as appwglw6njb6wMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/app.vue?macro=true";
import { default as indexhb5tkzmkdhMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/bridge/index.vue?macro=true";
import { default as examplesXuA9wFHsUSMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/examples.vue?macro=true";
import { default as index8YBgmDAIX5Meta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/index.vue?macro=true";
import { default as indexg8vh06cfkJMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/peer/index.vue?macro=true";
import { default as peeriXaIo39ZhvMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/peer.vue?macro=true";
import { default as routerG4oVvdJ2JGMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use/router.vue?macro=true";
import { default as useJYRhBqbkmuMeta } from "/usr/src/nuxt-app/pages/projects/[projectId]/use.vue?macro=true";
import { default as _91projectId_93CCi4ipXZRfMeta } from "/usr/src/nuxt-app/pages/projects/[projectId].vue?macro=true";
import { default as listfsmY2KpBo8Meta } from "/usr/src/nuxt-app/pages/projects/list.vue?macro=true";
import { default as wizardClgHXvAMHaMeta } from "/usr/src/nuxt-app/pages/projects/wizard.vue?macro=true";
import { default as projectsf96a3uaMQNMeta } from "/usr/src/nuxt-app/pages/projects.vue?macro=true";
import { default as registeriJTikDeqLiMeta } from "/usr/src/nuxt-app/layers/registration/pages/register.vue?macro=true";
import { default as terms_45and_45conditionsG1HXfy5wmRMeta } from "/usr/src/nuxt-app/pages/terms-and-conditions.vue?macro=true";
import { default as organisationf7UhwA1XRjMeta } from "/usr/src/nuxt-app/pages/user/organisation.vue?macro=true";
import { default as profileNITQh9CMlPMeta } from "/usr/src/nuxt-app/pages/user/profile.vue?macro=true";
import { default as userOSDMZKsciDMeta } from "/usr/src/nuxt-app/pages/user.vue?macro=true";
import { default as welcomeY6JR4ATV3OMeta } from "/usr/src/nuxt-app/pages/welcome.vue?macro=true";
export default [
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginlzkgvgJgWgMeta?.name ?? "login",
    path: loginlzkgvgJgWgMeta?.path ?? "/login",
    meta: loginlzkgvgJgWgMeta || {},
    alias: loginlzkgvgJgWgMeta?.alias || [],
    redirect: loginlzkgvgJgWgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutPCUZmFcBWgMeta?.name ?? "logout",
    path: logoutPCUZmFcBWgMeta?.path ?? "/logout",
    meta: logoutPCUZmFcBWgMeta || {},
    alias: logoutPCUZmFcBWgMeta?.alias || [],
    redirect: logoutPCUZmFcBWgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: not_45availableiAmPBLu0HyMeta?.name ?? "not-available",
    path: not_45availableiAmPBLu0HyMeta?.path ?? "/not-available",
    meta: not_45availableiAmPBLu0HyMeta || {},
    alias: not_45availableiAmPBLu0HyMeta?.alias || [],
    redirect: not_45availableiAmPBLu0HyMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/not-available.vue").then(m => m.default || m)
  },
  {
    name: organisationR0Nx3BUBjAMeta?.name ?? "organisation",
    path: organisationR0Nx3BUBjAMeta?.path ?? "/organisation",
    meta: organisationR0Nx3BUBjAMeta || {},
    alias: organisationR0Nx3BUBjAMeta?.alias || [],
    redirect: organisationR0Nx3BUBjAMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/organisation.vue").then(m => m.default || m),
    children: [
  {
    name: billingRAOFT88ONgMeta?.name ?? "organisation-billing",
    path: billingRAOFT88ONgMeta?.path ?? "billing",
    meta: billingRAOFT88ONgMeta || {},
    alias: billingRAOFT88ONgMeta?.alias || [],
    redirect: billingRAOFT88ONgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/organisation/billing.vue").then(m => m.default || m)
  },
  {
    name: paymentskX1ZNGVSKoMeta?.name ?? "organisation-payments",
    path: paymentskX1ZNGVSKoMeta?.path ?? "payments",
    meta: paymentskX1ZNGVSKoMeta || {},
    alias: paymentskX1ZNGVSKoMeta?.alias || [],
    redirect: paymentskX1ZNGVSKoMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/organisation/payments.vue").then(m => m.default || m)
  },
  {
    name: upgradeFXb4Abtnn3Meta?.name ?? "organisation-upgrade",
    path: upgradeFXb4Abtnn3Meta?.path ?? "upgrade",
    meta: upgradeFXb4Abtnn3Meta || {},
    alias: upgradeFXb4Abtnn3Meta?.alias || [],
    redirect: upgradeFXb4Abtnn3Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/organisation/upgrade.vue").then(m => m.default || m)
  }
]
  },
  {
    name: playgroundtQghnXVMeBMeta?.name ?? "playground",
    path: playgroundtQghnXVMeBMeta?.path ?? "/playground",
    meta: playgroundtQghnXVMeBMeta || {},
    alias: playgroundtQghnXVMeBMeta?.alias || [],
    redirect: playgroundtQghnXVMeBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: pricingO3tVJMzC74Meta?.name ?? "pricing",
    path: pricingO3tVJMzC74Meta?.path ?? "/pricing",
    meta: pricingO3tVJMzC74Meta || {},
    alias: pricingO3tVJMzC74Meta?.alias || [],
    redirect: pricingO3tVJMzC74Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: projectsf96a3uaMQNMeta?.name ?? "projects",
    path: projectsf96a3uaMQNMeta?.path ?? "/projects",
    meta: projectsf96a3uaMQNMeta || {},
    alias: projectsf96a3uaMQNMeta?.alias || [],
    redirect: projectsf96a3uaMQNMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects.vue").then(m => m.default || m),
    children: [
  {
    name: _91projectId_93CCi4ipXZRfMeta?.name ?? undefined,
    path: _91projectId_93CCi4ipXZRfMeta?.path ?? ":projectId()",
    meta: _91projectId_93CCi4ipXZRfMeta || {},
    alias: _91projectId_93CCi4ipXZRfMeta?.alias || [],
    redirect: _91projectId_93CCi4ipXZRfMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId].vue").then(m => m.default || m),
    children: [
  {
    name: clientsaoDWpP8ly2Meta?.name ?? "projects-projectId-clients",
    path: clientsaoDWpP8ly2Meta?.path ?? "clients",
    meta: clientsaoDWpP8ly2Meta || {},
    alias: clientsaoDWpP8ly2Meta?.alias || [],
    redirect: clientsaoDWpP8ly2Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/clients.vue").then(m => m.default || m)
  },
  {
    name: dashboardsqeenRq8thYMeta?.name ?? "projects-projectId-dashboards",
    path: dashboardsqeenRq8thYMeta?.path ?? "dashboards",
    meta: dashboardsqeenRq8thYMeta || {},
    alias: dashboardsqeenRq8thYMeta?.alias || [],
    redirect: dashboardsqeenRq8thYMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/dashboards.vue").then(m => m.default || m),
    children: [
  {
    name: _91dashboardId_93MJYUgjEwssMeta?.name ?? "projects-projectId-dashboards-dashboardId",
    path: _91dashboardId_93MJYUgjEwssMeta?.path ?? ":dashboardId()",
    meta: _91dashboardId_93MJYUgjEwssMeta || {},
    alias: _91dashboardId_93MJYUgjEwssMeta?.alias || [],
    redirect: _91dashboardId_93MJYUgjEwssMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/dashboards/[dashboardId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: ddsC0UbNdWmNBMeta?.name ?? undefined,
    path: ddsC0UbNdWmNBMeta?.path ?? "dds",
    meta: ddsC0UbNdWmNBMeta || {},
    alias: ddsC0UbNdWmNBMeta?.alias || [],
    redirect: ddsC0UbNdWmNBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds.vue").then(m => m.default || m),
    children: [
  {
    name: domainslhd3A1Y7oOMeta?.name ?? undefined,
    path: domainslhd3A1Y7oOMeta?.path ?? "domains",
    meta: domainslhd3A1Y7oOMeta || {},
    alias: domainslhd3A1Y7oOMeta?.alias || [],
    redirect: domainslhd3A1Y7oOMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains.vue").then(m => m.default || m),
    children: [
  {
    name: _91domainId_93JbQIFcPyfqMeta?.name ?? undefined,
    path: _91domainId_93JbQIFcPyfqMeta?.path ?? ":domainId()",
    meta: _91domainId_93JbQIFcPyfqMeta || {},
    alias: _91domainId_93JbQIFcPyfqMeta?.alias || [],
    redirect: _91domainId_93JbQIFcPyfqMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId].vue").then(m => m.default || m),
    children: [
  {
    name: indexcQjuiTOdweMeta?.name ?? "projects-projectId-dds-domains-domainId",
    path: indexcQjuiTOdweMeta?.path ?? "",
    meta: indexcQjuiTOdweMeta || {},
    alias: indexcQjuiTOdweMeta?.alias || [],
    redirect: indexcQjuiTOdweMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/index.vue").then(m => m.default || m)
  },
  {
    name: participantsn7g1H1TUKSMeta?.name ?? undefined,
    path: participantsn7g1H1TUKSMeta?.path ?? "participants",
    meta: participantsn7g1H1TUKSMeta || {},
    alias: participantsn7g1H1TUKSMeta?.alias || [],
    redirect: participantsn7g1H1TUKSMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants.vue").then(m => m.default || m),
    children: [
  {
    name: _91participantId_93VzUUEOyIbbMeta?.name ?? undefined,
    path: _91participantId_93VzUUEOyIbbMeta?.path ?? ":participantId()",
    meta: _91participantId_93VzUUEOyIbbMeta || {},
    alias: _91participantId_93VzUUEOyIbbMeta?.alias || [],
    redirect: _91participantId_93VzUUEOyIbbMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId].vue").then(m => m.default || m),
    children: [
  {
    name: indexjyUG6874tUMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId",
    path: indexjyUG6874tUMeta?.path ?? "",
    meta: indexjyUG6874tUMeta || {},
    alias: indexjyUG6874tUMeta?.alias || [],
    redirect: indexjyUG6874tUMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/index.vue").then(m => m.default || m)
  },
  {
    name: readersKa0B3QIggvMeta?.name ?? undefined,
    path: readersKa0B3QIggvMeta?.path ?? "readers",
    meta: readersKa0B3QIggvMeta || {},
    alias: readersKa0B3QIggvMeta?.alias || [],
    redirect: readersKa0B3QIggvMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/readers.vue").then(m => m.default || m),
    children: [
  {
    name: indexxco4FmmY5AMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-readers-readerId",
    path: indexxco4FmmY5AMeta?.path ?? ":readerId()",
    meta: indexxco4FmmY5AMeta || {},
    alias: indexxco4FmmY5AMeta?.alias || [],
    redirect: indexxco4FmmY5AMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/readers/[readerId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBxOv6kwNz1Meta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-readers",
    path: indexBxOv6kwNz1Meta?.path ?? "",
    meta: indexBxOv6kwNz1Meta || {},
    alias: indexBxOv6kwNz1Meta?.alias || [],
    redirect: indexBxOv6kwNz1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/readers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: topicsObsQPsa1gbMeta?.name ?? undefined,
    path: topicsObsQPsa1gbMeta?.path ?? "topics",
    meta: topicsObsQPsa1gbMeta || {},
    alias: topicsObsQPsa1gbMeta?.alias || [],
    redirect: topicsObsQPsa1gbMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics.vue").then(m => m.default || m),
    children: [
  {
    name: _91topicName_93XWlK2jdkMzMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-topics-topicName",
    path: _91topicName_93XWlK2jdkMzMeta?.path ?? ":topicName()",
    meta: _91topicName_93XWlK2jdkMzMeta || {},
    alias: _91topicName_93XWlK2jdkMzMeta?.alias || [],
    redirect: _91topicName_93XWlK2jdkMzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName].vue").then(m => m.default || m),
    children: [
  {
    name: readersk7pYM88WwMMeta?.name ?? undefined,
    path: readersk7pYM88WwMMeta?.path ?? "readers",
    meta: readersk7pYM88WwMMeta || {},
    alias: readersk7pYM88WwMMeta?.alias || [],
    redirect: readersk7pYM88WwMMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/readers.vue").then(m => m.default || m),
    children: [
  {
    name: indexEuiILiVXpLMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-topics-topicName-readers-readerId",
    path: indexEuiILiVXpLMeta?.path ?? ":readerId()",
    meta: indexEuiILiVXpLMeta || {},
    alias: indexEuiILiVXpLMeta?.alias || [],
    redirect: indexEuiILiVXpLMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/readers/[readerId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvdHnr7a5YrMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-topics-topicName-readers",
    path: indexvdHnr7a5YrMeta?.path ?? "",
    meta: indexvdHnr7a5YrMeta || {},
    alias: indexvdHnr7a5YrMeta?.alias || [],
    redirect: indexvdHnr7a5YrMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/readers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: writerstPXC1NVzFRMeta?.name ?? undefined,
    path: writerstPXC1NVzFRMeta?.path ?? "writers",
    meta: writerstPXC1NVzFRMeta || {},
    alias: writerstPXC1NVzFRMeta?.alias || [],
    redirect: writerstPXC1NVzFRMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/writers.vue").then(m => m.default || m),
    children: [
  {
    name: indexdqWNurvJArMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-topics-topicName-writers-writerId",
    path: indexdqWNurvJArMeta?.path ?? ":writerId()",
    meta: indexdqWNurvJArMeta || {},
    alias: indexdqWNurvJArMeta?.alias || [],
    redirect: indexdqWNurvJArMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/writers/[writerId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoomVgDw8A8Meta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-topics-topicName-writers",
    path: indexoomVgDw8A8Meta?.path ?? "",
    meta: indexoomVgDw8A8Meta || {},
    alias: indexoomVgDw8A8Meta?.alias || [],
    redirect: indexoomVgDw8A8Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/[topicName]/writers/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexHcXJ065VLpMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-topics",
    path: indexHcXJ065VLpMeta?.path ?? "",
    meta: indexHcXJ065VLpMeta || {},
    alias: indexHcXJ065VLpMeta?.alias || [],
    redirect: indexHcXJ065VLpMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/topics/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: writers3RnZqq4VUXMeta?.name ?? undefined,
    path: writers3RnZqq4VUXMeta?.path ?? "writers",
    meta: writers3RnZqq4VUXMeta || {},
    alias: writers3RnZqq4VUXMeta?.alias || [],
    redirect: writers3RnZqq4VUXMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/writers.vue").then(m => m.default || m),
    children: [
  {
    name: indexO2HKoGNfAeMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-writers-writerId",
    path: indexO2HKoGNfAeMeta?.path ?? ":writerId()",
    meta: indexO2HKoGNfAeMeta || {},
    alias: indexO2HKoGNfAeMeta?.alias || [],
    redirect: indexO2HKoGNfAeMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/writers/[writerId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJPXohpUOOMeta?.name ?? "projects-projectId-dds-domains-domainId-participants-participantId-writers",
    path: indexWJPXohpUOOMeta?.path ?? "",
    meta: indexWJPXohpUOOMeta || {},
    alias: indexWJPXohpUOOMeta?.alias || [],
    redirect: indexWJPXohpUOOMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/[participantId]/writers/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: index7M7p4ENrtJMeta?.name ?? "projects-projectId-dds-domains-domainId-participants",
    path: index7M7p4ENrtJMeta?.path ?? "",
    meta: index7M7p4ENrtJMeta || {},
    alias: index7M7p4ENrtJMeta?.alias || [],
    redirect: index7M7p4ENrtJMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/participants/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: topicsNHANz8PPcsMeta?.name ?? undefined,
    path: topicsNHANz8PPcsMeta?.path ?? "topics",
    meta: topicsNHANz8PPcsMeta || {},
    alias: topicsNHANz8PPcsMeta?.alias || [],
    redirect: topicsNHANz8PPcsMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics.vue").then(m => m.default || m),
    children: [
  {
    name: _91topicName_93bMpHWgS0qFMeta?.name ?? undefined,
    path: _91topicName_93bMpHWgS0qFMeta?.path ?? ":topicName()",
    meta: _91topicName_93bMpHWgS0qFMeta || {},
    alias: _91topicName_93bMpHWgS0qFMeta?.alias || [],
    redirect: _91topicName_93bMpHWgS0qFMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName].vue").then(m => m.default || m),
    children: [
  {
    name: indexVUcP8nD9fhMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName",
    path: indexVUcP8nD9fhMeta?.path ?? "",
    meta: indexVUcP8nD9fhMeta || {},
    alias: indexVUcP8nD9fhMeta?.alias || [],
    redirect: indexVUcP8nD9fhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/index.vue").then(m => m.default || m)
  },
  {
    name: participantsOZnwzu8cxjMeta?.name ?? undefined,
    path: participantsOZnwzu8cxjMeta?.path ?? "participants",
    meta: participantsOZnwzu8cxjMeta || {},
    alias: participantsOZnwzu8cxjMeta?.alias || [],
    redirect: participantsOZnwzu8cxjMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants.vue").then(m => m.default || m),
    children: [
  {
    name: _91participantId_933QtwzjeSPxMeta?.name ?? undefined,
    path: _91participantId_933QtwzjeSPxMeta?.path ?? ":participantId()",
    meta: _91participantId_933QtwzjeSPxMeta || {},
    alias: _91participantId_933QtwzjeSPxMeta?.alias || [],
    redirect: _91participantId_933QtwzjeSPxMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId].vue").then(m => m.default || m),
    children: [
  {
    name: indexvgy14xMfKnMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-participants-participantId",
    path: indexvgy14xMfKnMeta?.path ?? "",
    meta: indexvgy14xMfKnMeta || {},
    alias: indexvgy14xMfKnMeta?.alias || [],
    redirect: indexvgy14xMfKnMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/index.vue").then(m => m.default || m)
  },
  {
    name: readersVUONE0DmxvMeta?.name ?? undefined,
    path: readersVUONE0DmxvMeta?.path ?? "readers",
    meta: readersVUONE0DmxvMeta || {},
    alias: readersVUONE0DmxvMeta?.alias || [],
    redirect: readersVUONE0DmxvMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/readers.vue").then(m => m.default || m),
    children: [
  {
    name: index0vcIbacyyAMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-participants-participantId-readers-readerId",
    path: index0vcIbacyyAMeta?.path ?? ":readerId()",
    meta: index0vcIbacyyAMeta || {},
    alias: index0vcIbacyyAMeta?.alias || [],
    redirect: index0vcIbacyyAMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/readers/[readerId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexee7CzBquAOMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-participants-participantId-readers",
    path: indexee7CzBquAOMeta?.path ?? "",
    meta: indexee7CzBquAOMeta || {},
    alias: indexee7CzBquAOMeta?.alias || [],
    redirect: indexee7CzBquAOMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/readers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: writersXMla5wmOuiMeta?.name ?? undefined,
    path: writersXMla5wmOuiMeta?.path ?? "writers",
    meta: writersXMla5wmOuiMeta || {},
    alias: writersXMla5wmOuiMeta?.alias || [],
    redirect: writersXMla5wmOuiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/writers.vue").then(m => m.default || m),
    children: [
  {
    name: indexnJckgPyqVMMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-participants-participantId-writers-writerId",
    path: indexnJckgPyqVMMeta?.path ?? ":writerId()",
    meta: indexnJckgPyqVMMeta || {},
    alias: indexnJckgPyqVMMeta?.alias || [],
    redirect: indexnJckgPyqVMMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/writers/[writerId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXGUkAfAfLiMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-participants-participantId-writers",
    path: indexXGUkAfAfLiMeta?.path ?? "",
    meta: indexXGUkAfAfLiMeta || {},
    alias: indexXGUkAfAfLiMeta?.alias || [],
    redirect: indexXGUkAfAfLiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/[participantId]/writers/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: index1wH40pkkbiMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-participants",
    path: index1wH40pkkbiMeta?.path ?? "",
    meta: index1wH40pkkbiMeta || {},
    alias: index1wH40pkkbiMeta?.alias || [],
    redirect: index1wH40pkkbiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/participants/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: readersWVZeUyVqPGMeta?.name ?? undefined,
    path: readersWVZeUyVqPGMeta?.path ?? "readers",
    meta: readersWVZeUyVqPGMeta || {},
    alias: readersWVZeUyVqPGMeta?.alias || [],
    redirect: readersWVZeUyVqPGMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/readers.vue").then(m => m.default || m),
    children: [
  {
    name: indexAZOdXX4QwvMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-readers-readerId",
    path: indexAZOdXX4QwvMeta?.path ?? ":readerId()",
    meta: indexAZOdXX4QwvMeta || {},
    alias: indexAZOdXX4QwvMeta?.alias || [],
    redirect: indexAZOdXX4QwvMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/readers/[readerId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEKrmGrZg90Meta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-readers",
    path: indexEKrmGrZg90Meta?.path ?? "",
    meta: indexEKrmGrZg90Meta || {},
    alias: indexEKrmGrZg90Meta?.alias || [],
    redirect: indexEKrmGrZg90Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/readers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: writersdvRmcOHOYmMeta?.name ?? undefined,
    path: writersdvRmcOHOYmMeta?.path ?? "writers",
    meta: writersdvRmcOHOYmMeta || {},
    alias: writersdvRmcOHOYmMeta?.alias || [],
    redirect: writersdvRmcOHOYmMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/writers.vue").then(m => m.default || m),
    children: [
  {
    name: indexS63f8ozO0gMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-writers-writerId",
    path: indexS63f8ozO0gMeta?.path ?? ":writerId()",
    meta: indexS63f8ozO0gMeta || {},
    alias: indexS63f8ozO0gMeta?.alias || [],
    redirect: indexS63f8ozO0gMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/writers/[writerId]/index.vue").then(m => m.default || m)
  },
  {
    name: index5bdWQFDzvGMeta?.name ?? "projects-projectId-dds-domains-domainId-topics-topicName-writers",
    path: index5bdWQFDzvGMeta?.path ?? "",
    meta: index5bdWQFDzvGMeta || {},
    alias: index5bdWQFDzvGMeta?.alias || [],
    redirect: index5bdWQFDzvGMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/[topicName]/writers/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexa3cJwSaPtlMeta?.name ?? "projects-projectId-dds-domains-domainId-topics",
    path: indexa3cJwSaPtlMeta?.path ?? "",
    meta: indexa3cJwSaPtlMeta || {},
    alias: indexa3cJwSaPtlMeta?.alias || [],
    redirect: indexa3cJwSaPtlMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/[domainId]/topics/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexhqJYHpsq04Meta?.name ?? "projects-projectId-dds-domains",
    path: indexhqJYHpsq04Meta?.path ?? "",
    meta: indexhqJYHpsq04Meta || {},
    alias: indexhqJYHpsq04Meta?.alias || [],
    redirect: indexhqJYHpsq04Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/index.vue").then(m => m.default || m)
  },
  {
    name: listyiFvakcwxKMeta?.name ?? "projects-projectId-dds-domains-list",
    path: listyiFvakcwxKMeta?.path ?? "list",
    meta: listyiFvakcwxKMeta || {},
    alias: listyiFvakcwxKMeta?.alias || [],
    redirect: listyiFvakcwxKMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/domains/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextrIBHnp1wRMeta?.name ?? "projects-projectId-dds",
    path: indextrIBHnp1wRMeta?.path ?? "",
    meta: indextrIBHnp1wRMeta || {},
    alias: indextrIBHnp1wRMeta?.alias || [],
    redirect: indextrIBHnp1wRMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/dds/pages/projects/[projectId]/dds/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: edit26PNnDK7sbMeta?.name ?? "projects-projectId-edit",
    path: edit26PNnDK7sbMeta?.path ?? "edit",
    meta: edit26PNnDK7sbMeta || {},
    alias: edit26PNnDK7sbMeta?.alias || [],
    redirect: edit26PNnDK7sbMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexef9lNezxV7Meta?.name ?? "projects-projectId",
    path: indexef9lNezxV7Meta?.path ?? "",
    meta: indexef9lNezxV7Meta || {},
    alias: indexef9lNezxV7Meta?.alias || [],
    redirect: indexef9lNezxV7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: maplJdJQQdPFFMeta?.name ?? "projects-projectId-map",
    path: maplJdJQQdPFFMeta?.path ?? "map",
    meta: maplJdJQQdPFFMeta || {},
    alias: maplJdJQQdPFFMeta?.alias || [],
    redirect: maplJdJQQdPFFMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/map.vue").then(m => m.default || m)
  },
  {
    name: metricsehLqZ2GIPAMeta?.name ?? "projects-projectId-metrics",
    path: metricsehLqZ2GIPAMeta?.path ?? "metrics",
    meta: metricsehLqZ2GIPAMeta || {},
    alias: metricsehLqZ2GIPAMeta?.alias || [],
    redirect: metricsehLqZ2GIPAMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/metrics.vue").then(m => m.default || m)
  },
  {
    name: routersJmcPZtsUpEMeta?.name ?? undefined,
    path: routersJmcPZtsUpEMeta?.path ?? "routers",
    meta: routersJmcPZtsUpEMeta || {},
    alias: routersJmcPZtsUpEMeta?.alias || [],
    redirect: routersJmcPZtsUpEMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers.vue").then(m => m.default || m),
    children: [
  {
    name: _91routerId_93uSD1tTDAgdMeta?.name ?? undefined,
    path: _91routerId_93uSD1tTDAgdMeta?.path ?? ":routerId()",
    meta: _91routerId_93uSD1tTDAgdMeta || {},
    alias: _91routerId_93uSD1tTDAgdMeta?.alias || [],
    redirect: _91routerId_93uSD1tTDAgdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId].vue").then(m => m.default || m),
    children: [
  {
    name: indexVddh4X5fQCMeta?.name ?? "projects-projectId-routers-routerId",
    path: indexVddh4X5fQCMeta?.path ?? "",
    meta: indexVddh4X5fQCMeta || {},
    alias: indexVddh4X5fQCMeta?.alias || [],
    redirect: indexVddh4X5fQCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/index.vue").then(m => m.default || m)
  },
  {
    name: infoYT4Z2fY6BhMeta?.name ?? "projects-projectId-routers-routerId-info",
    path: infoYT4Z2fY6BhMeta?.path ?? "info",
    meta: infoYT4Z2fY6BhMeta || {},
    alias: infoYT4Z2fY6BhMeta?.alias || [],
    redirect: infoYT4Z2fY6BhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/info.vue").then(m => m.default || m)
  },
  {
    name: metricsfawFhpJezEMeta?.name ?? "projects-projectId-routers-routerId-metrics",
    path: metricsfawFhpJezEMeta?.path ?? "metrics",
    meta: metricsfawFhpJezEMeta || {},
    alias: metricsfawFhpJezEMeta?.alias || [],
    redirect: metricsfawFhpJezEMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/metrics.vue").then(m => m.default || m)
  },
  {
    name: pluginsCiz41ZtC44Meta?.name ?? "projects-projectId-routers-routerId-plugins",
    path: pluginsCiz41ZtC44Meta?.path ?? "plugins",
    meta: pluginsCiz41ZtC44Meta || {},
    alias: pluginsCiz41ZtC44Meta?.alias || [],
    redirect: pluginsCiz41ZtC44Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/plugins.vue").then(m => m.default || m)
  },
  {
    name: storagesZmMwvPFi4hMeta?.name ?? "projects-projectId-routers-routerId-storages",
    path: storagesZmMwvPFi4hMeta?.path ?? "storages",
    meta: storagesZmMwvPFi4hMeta || {},
    alias: storagesZmMwvPFi4hMeta?.alias || [],
    redirect: storagesZmMwvPFi4hMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/[routerId]/storages.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsg0BzHEP56Meta?.name ?? "projects-projectId-routers",
    path: indexsg0BzHEP56Meta?.path ?? "",
    meta: indexsg0BzHEP56Meta || {},
    alias: indexsg0BzHEP56Meta?.alias || [],
    redirect: indexsg0BzHEP56Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/index.vue").then(m => m.default || m)
  },
  {
    name: listKKLRbYBocxMeta?.name ?? "projects-projectId-routers-list",
    path: listKKLRbYBocxMeta?.path ?? "list",
    meta: listKKLRbYBocxMeta || {},
    alias: listKKLRbYBocxMeta?.alias || [],
    redirect: listKKLRbYBocxMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/routers/list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: useJYRhBqbkmuMeta?.name ?? undefined,
    path: useJYRhBqbkmuMeta?.path ?? "use",
    meta: useJYRhBqbkmuMeta || {},
    alias: useJYRhBqbkmuMeta?.alias || [],
    redirect: useJYRhBqbkmuMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use.vue").then(m => m.default || m),
    children: [
  {
    name: appwglw6njb6wMeta?.name ?? undefined,
    path: appwglw6njb6wMeta?.path ?? "app",
    meta: appwglw6njb6wMeta || {},
    alias: appwglw6njb6wMeta?.alias || [],
    redirect: appwglw6njb6wMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/app.vue").then(m => m.default || m),
    children: [
  {
    name: indexQMDokDA4S7Meta?.name ?? "projects-projectId-use-app",
    path: indexQMDokDA4S7Meta?.path ?? "",
    meta: indexQMDokDA4S7Meta || {},
    alias: indexQMDokDA4S7Meta?.alias || [],
    redirect: indexQMDokDA4S7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/app/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexhb5tkzmkdhMeta?.name ?? "projects-projectId-use-bridge",
    path: indexhb5tkzmkdhMeta?.path ?? "bridge",
    meta: indexhb5tkzmkdhMeta || {},
    alias: indexhb5tkzmkdhMeta?.alias || [],
    redirect: indexhb5tkzmkdhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/bridge/index.vue").then(m => m.default || m)
  },
  {
    name: examplesXuA9wFHsUSMeta?.name ?? "projects-projectId-use-examples",
    path: examplesXuA9wFHsUSMeta?.path ?? "examples",
    meta: examplesXuA9wFHsUSMeta || {},
    alias: examplesXuA9wFHsUSMeta?.alias || [],
    redirect: examplesXuA9wFHsUSMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/examples.vue").then(m => m.default || m)
  },
  {
    name: index8YBgmDAIX5Meta?.name ?? "projects-projectId-use",
    path: index8YBgmDAIX5Meta?.path ?? "",
    meta: index8YBgmDAIX5Meta || {},
    alias: index8YBgmDAIX5Meta?.alias || [],
    redirect: index8YBgmDAIX5Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/index.vue").then(m => m.default || m)
  },
  {
    name: peeriXaIo39ZhvMeta?.name ?? undefined,
    path: peeriXaIo39ZhvMeta?.path ?? "peer",
    meta: peeriXaIo39ZhvMeta || {},
    alias: peeriXaIo39ZhvMeta?.alias || [],
    redirect: peeriXaIo39ZhvMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/peer.vue").then(m => m.default || m),
    children: [
  {
    name: indexg8vh06cfkJMeta?.name ?? "projects-projectId-use-peer",
    path: indexg8vh06cfkJMeta?.path ?? "",
    meta: indexg8vh06cfkJMeta || {},
    alias: indexg8vh06cfkJMeta?.alias || [],
    redirect: indexg8vh06cfkJMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/peer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: routerG4oVvdJ2JGMeta?.name ?? "projects-projectId-use-router",
    path: routerG4oVvdJ2JGMeta?.path ?? "router",
    meta: routerG4oVvdJ2JGMeta || {},
    alias: routerG4oVvdJ2JGMeta?.alias || [],
    redirect: routerG4oVvdJ2JGMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/[projectId]/use/router.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: listfsmY2KpBo8Meta?.name ?? "projects-list",
    path: listfsmY2KpBo8Meta?.path ?? "list",
    meta: listfsmY2KpBo8Meta || {},
    alias: listfsmY2KpBo8Meta?.alias || [],
    redirect: listfsmY2KpBo8Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/list.vue").then(m => m.default || m)
  },
  {
    name: wizardClgHXvAMHaMeta?.name ?? "projects-wizard",
    path: wizardClgHXvAMHaMeta?.path ?? "wizard",
    meta: wizardClgHXvAMHaMeta || {},
    alias: wizardClgHXvAMHaMeta?.alias || [],
    redirect: wizardClgHXvAMHaMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/projects/wizard.vue").then(m => m.default || m)
  }
]
  },
  {
    name: registeriJTikDeqLiMeta?.name ?? "register",
    path: registeriJTikDeqLiMeta?.path ?? "/register",
    meta: registeriJTikDeqLiMeta || {},
    alias: registeriJTikDeqLiMeta?.alias || [],
    redirect: registeriJTikDeqLiMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/layers/registration/pages/register.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsG1HXfy5wmRMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsG1HXfy5wmRMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsG1HXfy5wmRMeta || {},
    alias: terms_45and_45conditionsG1HXfy5wmRMeta?.alias || [],
    redirect: terms_45and_45conditionsG1HXfy5wmRMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: userOSDMZKsciDMeta?.name ?? "user",
    path: userOSDMZKsciDMeta?.path ?? "/user",
    meta: userOSDMZKsciDMeta || {},
    alias: userOSDMZKsciDMeta?.alias || [],
    redirect: userOSDMZKsciDMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: organisationf7UhwA1XRjMeta?.name ?? "user-organisation",
    path: organisationf7UhwA1XRjMeta?.path ?? "organisation",
    meta: organisationf7UhwA1XRjMeta || {},
    alias: organisationf7UhwA1XRjMeta?.alias || [],
    redirect: organisationf7UhwA1XRjMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/user/organisation.vue").then(m => m.default || m)
  },
  {
    name: profileNITQh9CMlPMeta?.name ?? "user-profile",
    path: profileNITQh9CMlPMeta?.path ?? "profile",
    meta: profileNITQh9CMlPMeta || {},
    alias: profileNITQh9CMlPMeta?.alias || [],
    redirect: profileNITQh9CMlPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/user/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: welcomeY6JR4ATV3OMeta?.name ?? "welcome",
    path: welcomeY6JR4ATV3OMeta?.path ?? "/welcome",
    meta: welcomeY6JR4ATV3OMeta || {},
    alias: welcomeY6JR4ATV3OMeta?.alias || [],
    redirect: welcomeY6JR4ATV3OMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/welcome.vue").then(m => m.default || m)
  }
]