<template>
    <v-textarea
        ref="field"
        density="compact"
        color="primary"
        class="app-textarea">
        <template
            v-for="(_, name, index) in $slots as {}"
            #[name]="scope"
            :key="index">
            <slot
                :name="name"
                v-bind="{ scope }"></slot>
        </template>
    </v-textarea>
</template>
<script setup lang="ts">
const field = ref();
defineExpose({
    field,
});
</script>
<style>
.app-textarea textarea {
    font-size: 0.8rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
    font-family: 'Epilogue', serif !important;
    text-transform: none !important;
}
</style>
