import { helpers } from '@vuelidate/validators';

async function shouldBeKeyExpr(value: string) {
    if (!helpers.req(value)) {
        return true;
    }

    const response = await fetch(
        `/api/utils/keyexpr/isValid?input=${encodeURI(value)}`
    );
    const r = await response.json();

    return r.isValid === true;
}

export default helpers.withAsync(
    helpers.withParams({ type: 'shouldBeKeyExpr' }, shouldBeKeyExpr)
);
