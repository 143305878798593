import type { ThemeDefinition } from 'vuetify';

export default {
    dark: true,
    colors: {
        appbar: '#070219',
        background: '#1c1c1c',
        'background-secondary': '#141725', // Adjusted: '#02040D',
        primary: '#3cabe4',
        // 14b4ff
        'virtual-edge-color': '#FFAAFF',
        'project-edge-color': '#1FF5FF',
        'secured-edge-color': '#14b4ff',
        'unsecured-edge-color': '#1433ff',
        secondary: '#FFF',
        error: '#F00',
        'on-primary': '#1c1c1c',
        // 'on-background': '#a7a3a3', // Adjusted
        // 'on-surface': '#FFF', // Adjusted
        // 'on-secondary': '#05061f87', // Adjusted
        // 'on-success': '#05061f87', // Adjusted
        // 'on-warning': '#05061f87', // Adjusted
        // 'on-error': '#FFF', // Adjusted
        // 'on-info': '#040927',
        // 'header-ext-bg': '#040927',
    },
} as ThemeDefinition;
