//  Public tokens that are determined at build time, website configuration such as theme variant, title and any project config that are not sensitive.
export default defineAppConfig({
    name: 'Zettascale Platform',
    zenoh: {
        network: {
            keepAliveInterval: 5000,
            connectionRetry: 4,
            periodicRefreshInterval: 10000,
        },
        ui: {
            defaultView: 'List',
            defaultDataView: 'Dashboard',
        },
    },
    dashboard: {
        widgets: {
            defaultWidth: 5,
            defaultHeight: 5,
        },
        subscribtionHistorySize: 50,
    },
    system: {
        serviceAuthHeaderName: 'Authorization',
        /**
         * Average Deployement duration
         * Should be in seconds
         */
        averageDeployDuration: 180, // 3min
        maxPaasRoutersPerProject: 30,
    },
    ux: {
        projectRefreshInterval: 25 * 1000,
        alwaysShowProjectDesc: false,
        pinMenuKey: 'Ctrl+m',
    },
    connectors: {
        mqtt: {
            defaultPort: 4343,
        },
    },
    socials: {
        github: '',
        twitter: '',
        discord: '',
        email: '',
    },
    sampleLinks: {
        zsub: 'https://raw.githubusercontent.com/eclipse-zenoh/zenoh-python/master/examples/z_sub.py',
        zpub: 'https://raw.githubusercontent.com/eclipse-zenoh/zenoh-python/master/examples/z_pub.py',
    },
    helpLinks: {
        dds: {
            scope: 'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L13',
            queries_timeout:
                'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L100C12-L100C27',
            reliable_routes_blocking:
                'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L90',
            forward_discovery:
                'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L83',
            max_frequencies:
                'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L63',
            group_member_id:
                'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L31',
            domain: 'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L20',
            allow: 'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L37',
            deny: 'https://github.com/eclipse-zenoh/zenoh-plugin-dds/blob/eefc16f0997da6b6515c09adfba3fb1b26147eff/DEFAULT_CONFIG.json5#L50',
        },
        mqtt: {
            port: 'https://github.com/eclipse-zenoh/zenoh-plugin-mqtt/blob/2b99e22b5744dd42fd1f3396678eb549fd22fd88/DEFAULT_CONFIG.json5#L13',
            scope: 'https://github.com/eclipse-zenoh/zenoh-plugin-mqtt/blob/2b99e22b5744dd42fd1f3396678eb549fd22fd88/DEFAULT_CONFIG.json5#L20C12-L20C17',
            allow: 'https://github.com/eclipse-zenoh/zenoh-plugin-mqtt/blob/2b99e22b5744dd42fd1f3396678eb549fd22fd88/DEFAULT_CONFIG.json5#L27C12-L27C17',
            deny: 'https://github.com/eclipse-zenoh/zenoh-plugin-mqtt/blob/2b99e22b5744dd42fd1f3396678eb549fd22fd88/DEFAULT_CONFIG.json5#L33',
        },
        zenoh: [
            {
                label: 'rust',
                url: 'https://docs.rs/zenoh/latest/zenoh/',
                svg: 'useful_links/rust.svg',
                mdicon: 'mdi-language-rust',
            },
            {
                label: 'python',
                url: 'https://zenoh-python.readthedocs.io/',
                svg: 'useful_links/python.svg',
                mdicon: 'mdi-language-python',
            },
            {
                label: 'C',
                url: 'https://zenoh-c.readthedocs.io/',
                svg: 'useful_links/c.svg',
                mdicon: 'mdi-language-c',
            },

            {
                label: 'Rest',
                url: 'https://zenoh.io/docs/apis/rest/',
                svg: 'useful_links/rest.svg',
                mdicon: 'mdi-code-braces',
            },
        ],
    },
    features: {
        storages: {
            edition: {
                enabled: false,
            },
            deletion: {
                enabled: false,
            },
        },
        volumes: {
            edition: {
                enabled: false,
            },
            deletion: {
                enabled: false,
            },
        },
    },
    contactUs: {
        email: 'contacts@zettascale.tech',
    },
    countries: {
        AF: { name: 'Afghanistan', countryCode: '004' },
        AX: { name: 'Åland Islands', countryCode: '248' },
        AL: { name: 'Albania', countryCode: '008' },
        DZ: { name: 'Algeria', countryCode: '012' },
        AS: { name: 'American Samoa', countryCode: '016' },
        AD: { name: 'Andorra', countryCode: '020', euro: true },
        AO: { name: 'Angola', countryCode: '024' },
        AI: { name: 'Anguilla', countryCode: '660' },
        AQ: { name: 'Antarctica', countryCode: '010' },
        AG: { name: 'Antigua and Barbuda', countryCode: '028' },
        AR: { name: 'Argentina', countryCode: '032' },
        AM: { name: 'Armenia', countryCode: '051' },
        AW: { name: 'Aruba', countryCode: '533' },
        AU: { name: 'Australia', countryCode: '036' },
        AT: { name: 'Austria', countryCode: '040', euro: true },
        AZ: { name: 'Azerbaijan', countryCode: '031' },
        BS: { name: 'Bahamas', countryCode: '044' },
        BH: { name: 'Bahrain', countryCode: '048' },
        BD: { name: 'Bangladesh', countryCode: '050' },
        BB: { name: 'Barbados', countryCode: '052' },
        BY: { name: 'Belarus', countryCode: '112' },
        BE: { name: 'Belgium', countryCode: '056', euro: true },
        BZ: { name: 'Belize', countryCode: '084' },
        BJ: { name: 'Benin', countryCode: '204' },
        BM: { name: 'Bermuda', countryCode: '060' },
        BT: { name: 'Bhutan', countryCode: '064' },
        BO: { name: 'Bolivia (Plurinational State of)', countryCode: '068' },
        BQ: { name: 'Bonaire, Sint Eustatius and Saba', countryCode: '535' },
        BA: { name: 'Bosnia and Herzegovina', countryCode: '070' },
        BW: { name: 'Botswana', countryCode: '072' },
        BV: { name: 'Bouvet Island', countryCode: '074' },
        BR: { name: 'Brazil', countryCode: '076' },
        IO: { name: 'British Indian Ocean Territory', countryCode: '086' },
        BN: { name: 'Brunei Darussalam', countryCode: '096' },
        BG: { name: 'Bulgaria', countryCode: '100' },
        BF: { name: 'Burkina Faso', countryCode: '854' },
        BI: { name: 'Burundi', countryCode: '108' },
        CV: { name: 'Cabo Verde', countryCode: '132' },
        KH: { name: 'Cambodia', countryCode: '116' },
        CM: { name: 'Cameroon', countryCode: '120' },
        CA: { name: 'Canada', countryCode: '124' },
        KY: { name: 'Cayman Islands', countryCode: '136' },
        CF: { name: 'Central African Republic', countryCode: '140' },
        TD: { name: 'Chad', countryCode: '148' },
        CL: { name: 'Chile', countryCode: '152' },
        CN: { name: 'China', countryCode: '156' },
        CX: { name: 'Christmas Island', countryCode: '162' },
        CC: { name: 'Cocos (Keeling) Islands', countryCode: '166' },
        CO: { name: 'Colombia', countryCode: '170' },
        KM: { name: 'Comoros', countryCode: '174' },
        CG: { name: 'Congo', countryCode: '178' },
        CD: { name: 'Congo, Democratic Republic of the', countryCode: '180' },
        CK: { name: 'Cook Islands', countryCode: '184' },
        CR: { name: 'Costa Rica', countryCode: '188' },
        CI: { name: "Côte d'Ivoire", countryCode: '384' },
        HR: { name: 'Croatia', countryCode: '191', euro: true },
        CU: { name: 'Cuba', countryCode: '192' },
        CW: { name: 'Curaçao', countryCode: '531' },
        CY: { name: 'Cyprus', countryCode: '196', euro: true },
        CZ: { name: 'Czechia', countryCode: '203' },
        DK: { name: 'Denmark', countryCode: '208' },
        DJ: { name: 'Djibouti', countryCode: '262' },
        DM: { name: 'Dominica', countryCode: '212' },
        DO: { name: 'Dominican Republic', countryCode: '214' },
        EC: { name: 'Ecuador', countryCode: '218' },
        EG: { name: 'Egypt', countryCode: '818' },
        SV: { name: 'El Salvador', countryCode: '222' },
        GQ: { name: 'Equatorial Guinea', countryCode: '226' },
        ER: { name: 'Eritrea', countryCode: '232' },
        EE: { name: 'Estonia', countryCode: '233', euro: true },
        SZ: { name: 'Eswatini', countryCode: '748' },
        ET: { name: 'Ethiopia', countryCode: '231' },
        FK: { name: 'Falkland Islands (Malvinas)', countryCode: '238' },
        FO: { name: 'Faroe Islands', countryCode: '234' },
        FJ: { name: 'Fiji', countryCode: '242' },
        FI: { name: 'Finland', countryCode: '246', euro: true },
        FR: { name: 'France', countryCode: '250', euro: true },
        GF: { name: 'French Guiana', countryCode: '254', euro: true },
        PF: { name: 'French Polynesia', countryCode: '258' },
        TF: {
            name: 'French Southern Territories',
            countryCode: '260',
            euro: true,
        },
        GA: { name: 'Gabon', countryCode: '266' },
        GM: { name: 'Gambia', countryCode: '270' },
        GE: { name: 'Georgia', countryCode: '268' },
        DE: { name: 'Germany', countryCode: '276', euro: true },
        GH: { name: 'Ghana', countryCode: '288' },
        GI: { name: 'Gibraltar', countryCode: '292' },
        GR: { name: 'Greece', countryCode: '300', euro: true },
        GL: { name: 'Greenland', countryCode: '304' },
        GD: { name: 'Grenada', countryCode: '308' },
        GP: {
            name: 'Guadeloupe',
            countryCode: '312',
            euro: true,
        },
        GU: { name: 'Guam', countryCode: '316' },
        GT: { name: 'Guatemala', countryCode: '320' },
        GG: { name: 'Guernsey', countryCode: '831' },
        GN: { name: 'Guinea', countryCode: '324' },
        GW: { name: 'Guinea-Bissau', countryCode: '624' },
        GY: { name: 'Guyana', countryCode: '328' },
        HT: { name: 'Haiti', countryCode: '332' },
        HM: { name: 'Heard Island and McDonald Islands', countryCode: '334' },
        VA: {
            name: 'Holy See',
            countryCode: '336',
            euro: true,
        },
        HN: { name: 'Honduras', countryCode: '340' },
        HK: { name: 'Hong Kong', countryCode: '344' },
        HU: { name: 'Hungary', countryCode: '348' },
        IS: { name: 'Iceland', countryCode: '352' },
        IN: { name: 'India', countryCode: '356' },
        ID: { name: 'Indonesia', countryCode: '360' },
        IR: { name: 'Iran (Islamic Republic of)', countryCode: '364' },
        IQ: { name: 'Iraq', countryCode: '368' },
        IE: { name: 'Ireland', countryCode: '372', euro: true },
        IM: { name: 'Isle of Man', countryCode: '833' },
        IL: { name: 'Israel', countryCode: '376' },
        IT: { name: 'Italy', countryCode: '380', euro: true },
        JM: { name: 'Jamaica', countryCode: '388' },
        JP: { name: 'Japan', countryCode: '392' },
        JE: { name: 'Jersey', countryCode: '832' },
        JO: { name: 'Jordan', countryCode: '400' },
        KZ: { name: 'Kazakhstan', countryCode: '398' },
        KE: { name: 'Kenya', countryCode: '404' },
        KI: { name: 'Kiribati', countryCode: '296' },
        KP: {
            name: "Korea (Democratic People's Republic of)",
            countryCode: '408',
        },
        KR: { name: 'Korea, Republic of', countryCode: '410' },
        KW: { name: 'Kuwait', countryCode: '414' },
        KG: { name: 'Kyrgyzstan', countryCode: '417' },
        LA: {
            name: "Lao People's Democratic Republic",
            countryCode: '418',
        },
        LV: { name: 'Latvia', countryCode: '428', euro: true },
        LB: { name: 'Lebanon', countryCode: '422' },
        LS: { name: 'Lesotho', countryCode: '426' },
        LR: { name: 'Liberia', countryCode: '430' },
        LY: { name: 'Libya', countryCode: '434' },
        LI: { name: 'Liechtenstein', countryCode: '438' },
        LT: {
            name: 'Lithuania',
            countryCode: '440',
            euro: true,
        },
        LU: {
            name: 'Luxembourg',
            countryCode: '442',
            euro: true,
        },
        MO: { name: 'Macao', countryCode: '446' },
        MG: { name: 'Madagascar', countryCode: '450' },
        MW: { name: 'Malawi', countryCode: '454' },
        MY: { name: 'Malaysia', countryCode: '458' },
        MV: { name: 'Maldives', countryCode: '462' },
        ML: { name: 'Mali', countryCode: '466' },
        MT: { name: 'Malta', countryCode: '470', euro: true },
        MH: { name: 'Marshall Islands', countryCode: '584' },
        MQ: {
            name: 'Martinique',
            countryCode: '474',
            euro: true,
        },
        MR: { name: 'Mauritania', countryCode: '478' },
        MU: { name: 'Mauritius', countryCode: '480' },
        YT: { name: 'Mayotte', countryCode: '175', euro: true },
        MX: { name: 'Mexico', countryCode: '484' },
        FM: { name: 'Micronesia (Federated States of)', countryCode: '583' },
        MD: { name: 'Moldova, Republic of', countryCode: '498' },
        MC: { name: 'Monaco', countryCode: '492', euro: true },
        MN: { name: 'Mongolia', countryCode: '496' },
        ME: { name: 'Montenegro', countryCode: '499' },
        MS: { name: 'Montserrat', countryCode: '500' },
        MA: { name: 'Morocco', countryCode: '504' },
        MZ: { name: 'Mozambique', countryCode: '508' },
        MM: { name: 'Myanmar', countryCode: '104' },
        NA: { name: 'Namibia', countryCode: '516' },
        NR: { name: 'Nauru', countryCode: '520' },
        NP: { name: 'Nepal', countryCode: '524' },
        NL: {
            name: 'Netherlands',
            countryCode: '528',
            euro: true,
        },
        NC: { name: 'New Caledonia', countryCode: '540' },
        NZ: { name: 'New Zealand', countryCode: '554' },
        NI: { name: 'Nicaragua', countryCode: '558' },
        NE: { name: 'Niger', countryCode: '562' },
        NG: { name: 'Nigeria', countryCode: '566' },
        NU: { name: 'Niue', countryCode: '570' },
        NF: { name: 'Norfolk Island', countryCode: '574' },
        MK: { name: 'North Macedonia', countryCode: '807' },
        MP: { name: 'Northern Mariana Islands', countryCode: '580' },
        NO: { name: 'Norway', countryCode: '578' },
        OM: { name: 'Oman', countryCode: '512' },
        PK: { name: 'Pakistan', countryCode: '586' },
        PW: { name: 'Palau', countryCode: '585' },
        PS: { name: 'Palestine, State of', countryCode: '275' },
        PA: { name: 'Panama', countryCode: '591' },
        PG: { name: 'Papua New Guinea', countryCode: '598' },
        PY: { name: 'Paraguay', countryCode: '600' },
        PE: { name: 'Peru', countryCode: '604' },
        PH: { name: 'Philippines', countryCode: '608' },
        PN: { name: 'Pitcairn', countryCode: '612' },
        PL: { name: 'Poland', countryCode: '616' },
        PT: {
            name: 'Portugal',
            countryCode: '620',
            euro: true,
        },
        PR: { name: 'Puerto Rico', countryCode: '630' },
        QA: { name: 'Qatar', countryCode: '634' },
        RE: { name: 'Réunion', countryCode: '638', euro: true },
        RO: { name: 'Romania', countryCode: '642' },
        RU: { name: 'Russian Federation', countryCode: '643' },
        RW: { name: 'Rwanda', countryCode: '646' },
        BL: { name: 'Saint Barthélemy', countryCode: '652' },
        SH: {
            name: 'Saint Helena, Ascension and Tristan da Cunha',
            countryCode: '654',
        },
        KN: { name: 'Saint Kitts and Nevis', countryCode: '659' },
        LC: { name: 'Saint Lucia', countryCode: '662' },
        MF: { name: 'Saint Martin (French part)', countryCode: '663' },
        PM: {
            name: 'Saint Pierre and Miquelon',
            countryCode: '666',
            euro: true,
        },
        VC: { name: 'Saint Vincent and the Grenadines', countryCode: '670' },
        WS: { name: 'Samoa', countryCode: '882' },
        SM: {
            name: 'San Marino',
            countryCode: '674',
            euro: true,
        },
        ST: { name: 'Sao Tome and Principe', countryCode: '678' },
        SA: { name: 'Saudi Arabia', countryCode: '682' },
        SN: { name: 'Senegal', countryCode: '686' },
        RS: { name: 'Serbia', countryCode: '688' },
        SC: { name: 'Seychelles', countryCode: '690' },
        SL: { name: 'Sierra Leone', countryCode: '694' },
        SG: { name: 'Singapore', countryCode: '702' },
        SX: { name: 'Sint Maarten (Dutch part)', countryCode: '534' },
        SK: { name: 'Slovakia', countryCode: '703', euro: true },
        SI: { name: 'Slovenia', countryCode: '705', euro: true },
        SB: { name: 'Solomon Islands', countryCode: '090' },
        SO: { name: 'Somalia', countryCode: '706' },
        ZA: { name: 'South Africa', countryCode: '710' },
        GS: {
            name: 'South Georgia and the South Sandwich Islands',
            countryCode: '239',
        },
        SS: { name: 'South Sudan', countryCode: '728' },
        ES: { name: 'Spain', countryCode: '724', euro: true },
        LK: { name: 'Sri Lanka', countryCode: '144' },
        SD: { name: 'Sudan', countryCode: '729' },
        SR: { name: 'Suriname', countryCode: '740' },
        SJ: { name: 'Svalbard and Jan Mayen', countryCode: '744' },
        SE: { name: 'Sweden', countryCode: '752' },
        CH: { name: 'Switzerland', countryCode: '756' },
        SY: { name: 'Syrian Arab Republic', countryCode: '760' },
        TW: { name: 'Taiwan, Province of China', countryCode: '158' },
        TJ: { name: 'Tajikistan', countryCode: '762' },
        TZ: { name: 'Tanzania, United Republic of', countryCode: '834' },
        TH: { name: 'Thailand', countryCode: '764' },
        TL: { name: 'Timor-Leste', countryCode: '626' },
        TG: { name: 'Togo', countryCode: '768' },
        TK: { name: 'Tokelau', countryCode: '772' },
        TO: { name: 'Tonga', countryCode: '776' },
        TT: { name: 'Trinidad and Tobago', countryCode: '780' },
        TN: { name: 'Tunisia', countryCode: '788' },
        TR: { name: 'Turkey', countryCode: '792' },
        TM: { name: 'Turkmenistan', countryCode: '795' },
        TC: { name: 'Turks and Caicos Islands', countryCode: '796' },
        TV: { name: 'Tuvalu', countryCode: '798' },
        UG: { name: 'Uganda', countryCode: '800' },
        UA: { name: 'Ukraine', countryCode: '804' },
        AE: { name: 'United Arab Emirates', countryCode: '784' },
        GB: {
            name: 'United Kingdom of Great Britain and Northern Ireland',
            countryCode: '826',
        },
        US: { name: 'United States of America', countryCode: '840' },
        UM: {
            name: 'United States Minor Outlying Islands',
            countryCode: '581',
        },
        UY: { name: 'Uruguay', countryCode: '858' },
        UZ: { name: 'Uzbekistan', countryCode: '860' },
        VU: { name: 'Vanuatu', countryCode: '548' },
        VE: { name: 'Venezuela (Bolivarian Republic of)', countryCode: '862' },
        VN: { name: 'Viet Nam', countryCode: '704' },
        VG: { name: 'Virgin Islands (British)', countryCode: '092' },
        VI: { name: 'Virgin Islands (U.S.)', countryCode: '850' },
        WF: { name: 'Wallis and Futuna', countryCode: '876' },
        EH: { name: 'Western Sahara', countryCode: '732' },
        YE: { name: 'Yemen', countryCode: '887' },
        ZM: { name: 'Zambia', countryCode: '894' },
        ZW: { name: 'Zimbabwe', countryCode: '716' },
    },
});

declare module '@nuxt/schema' {
    interface AppConfigInput {
        countries: {
            [alpha2: string]: {
                name: string;
                countryCode: string;
                euro?: boolean;
            };
        };
    }
}
