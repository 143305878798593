import { h } from 'vue';
import type { IconAliases, IconProps } from 'vuetify';
import { VClassIcon } from 'vuetify/components';

const aliases: Partial<IconAliases> = {
    home: 'zet:zet-home',
    edit: 'zet:zet-pencil',
    list: 'zet:zet-ordered-list',
    delete: 'zet:zet-trash',
    eye: 'zet:zet-show-details',
    warning: 'zet:zet-warn',
    'warning-outlined': 'zet:zet-warn-outlined',
    info: 'zet:zet-info',
    'info-outlined': 'zet:zet-info-outlined',
    topology: 'zet:zet-topology',
    chart: 'zet:zet-speedometer',
    add: 'zet:zet-add',
    storage: 'zet:zet-storage',
    connector: 'zet:zet-connector',
    plus: 'mdi-plus',
    logout: 'zet:zet-logout',
    settings: 'zet:zet-settings',
    search: 'zet:zet-search',
    summary: 'zet:zet-summary',
    router: 'zet:zet-router',
    refresh: 'zet:zet-refresh',
    clock: 'zet:zet-clock',
    domain: 'zet:zet-buildings',
    theme: 'zet:zet-theme',
    none: 'zet:zet-none',
    pin: 'zet:zet-pin',
    check: 'mdi-check',
    'check-outlined': 'zet:zet-success-outlined',
    copy: 'zet:zet-copy',
    target: 'zet:zet-target',
    focus: 'zet:zet-focus',
    account: 'zet:zet-account',
    graph: 'zet:zet-graph',
    'client-apps': 'zet:zet-client-apps',
    'hierarchical-links': 'zet:zet-hierarchical-links',
    unsecured: 'zet:zet-unsecured',
    quic: 'zet:zet-quic',
    tls: 'zet:zet-tls',
    network: 'zet:zet-network',
    data: 'zet:zet-data',
    'dds-node': 'zet:zet-dds-node',
    'checklist-done': 'zet:zet-checklist-done',
    peer: 'zet:zet-peer',
    cancel: 'zet:zet-cancel',
    'chevron-down': 'mdi-chevron-down',
    'chevron-up': 'mdi-chevron-up',
    download: 'mdi-download',
    'download-outline': 'mdi-download-outline',
    close: 'mdi-close',
    raw: 'mdi-raw',
    folder: 'mdi-folder',
    upload: 'mdi-upload',
    play: 'mdi-play',
    'auto-fix': 'mdi-auto-fix',
    earth: 'mdi-earth',
    'arrow-left': 'mdi-arrow-left',
    'arrow-right': 'mdi-arrow-right',
    lan: 'zet:zet-lan',
    fullscreen: 'mdi-fullscreen',
    'code-json': 'mdi-code-json',
    help: 'mdi-help-circle',
    'help-outline': 'mdi-help-circle-outline',
    cloud: 'mdi-cloud-cog-outline',
    'access-point': 'mdi-access-point',
};

const zet = {
    // Not using mergeProps here, functional components merge props by default (?)
    component: (props: IconProps) =>
        h(VClassIcon, {
            ...props,
            class: 'zet',
        }),
};
export { aliases, zet };
