<template>
    <v-avatar
        class="avatar"
        :class="{ 'invert-dark': invertDark }"
        :image="avatarUrl" />
</template>
<script setup lang="ts">
type TProps = {
    email: string;
};
const props = defineProps<TProps>();

async function hash(string: string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
}

const avatarUrl = ref(
    (useRuntimeConfig().app.cdnURL || '/') + 'zenoh-avatar-white.svg'
);
const invertDark = ref(true);

onMounted(async () => {
    const email = props.email.trim();

    const $hash = await hash(email);

    $fetch(`https://gravatar.com/${$hash}`, {
        mode: 'no-cors',
        onResponse(result) {
            if (!result.error) {
                avatarUrl.value = `https://gravatar.com/avatar/${$hash}`;
                invertDark.value = false;
            }
        },
        onResponseError() {
            invertDark.value = true;
        },
    });
});
</script>
<style lang="scss">
.v-theme--light {
    .avatar {
        filter: invert(60%) sepia(9%) saturate(610%) hue-rotate(191deg)
            brightness(90%) contrast(89%);
    }
}
</style>
