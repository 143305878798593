<template>
    <v-container class="d-flex align-center justify-center fill-height">
        <v-progress-circular
            color="primary"
            class="app-loader drop-shadow-primary"
            indeterminate
            size="32"
            width="4"
            v-bind="$attrs" />
    </v-container>
</template>

<style lang="scss">
.app-loader {
    -webkit-animation: tt2 2s ease-in-out infinite;
    animation: tt2 2s ease-in-out infinite;
    filter: drop-shadow(0px 0px 5px rgba(var(--drop-color), 0.9))
        drop-shadow(0px 0px 1px rgba(var(--drop-color), 0.8));
}

@-webkit-keyframes tt2 {
    0% {
        scale: 0.8;
    }
    30% {
        scale: 1;
    }
    100% {
        scale: 0.8;
    }
}
@keyframes tt2 {
    0% {
        scale: 0.8;
    }
    30% {
        scale: 1;
    }
    100% {
        scale: 0.8;
    }
}
</style>
