<template>
    <v-dialog
        v-model="model"
        :fullscreen="xs"
        max-width="600px"
        absolute
        :transition="xs ? 'dialog-bottom-transition' : 'fade-transition'">
        <slot name="override-content">
            <v-card class="pa-2">
                <v-card-title class="">
                    <slot name="title" />
                </v-card-title>

                <v-card-text class="ml-n2 pr-2">
                    <slot />

                    <div class="d-flex justify-end pt-4">
                        <slot name="actions" />
                    </div>
                </v-card-text>
            </v-card>
        </slot>
    </v-dialog>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';

const model = defineModel<boolean>({
    required: true,
});

const { xs } = useDisplay();
</script>
<style lang="scss" scoped></style>
