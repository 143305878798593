export default defineAppConfig({
    dds: {
        policies: {
            deadline: {
                defaultConfiguration: {
                    period: undefined,
                },
                target: ['topic', 'dataReader', 'dataWriter'],
                rxo: true,
            },
            writer_data_lifecycle: {
                defaultConfiguration: {
                    autodispose_unregistered_instances: true,
                },
                target: ['dataWriter'],
            },
            reader_data_lifecycle: {
                defaultConfiguration: {
                    autopurge_nowriter_samples_delay: undefined,
                    autopurge_disposed_samples_delay: undefined,
                },
                target: ['dataReader'],
            },
            destination_order: {
                defaultConfiguration: {
                    kind: 'BY_RECEPTION_TIMESTAMP',
                },
                target: ['topic', 'dataReader', 'dataWriter'],
                rxo: true,
            },
            durability: {
                defaultConfiguration: {
                    kind: 'VOLATILE',
                },
                target: ['topic', 'dataReader', 'dataWriter'],
                rxo: true,
            },
            durability_service: {
                defaultConfiguration: {
                    service_cleanup_delay: 0,
                    history_kind: 'KEEP_LAST',
                    history_depth: 1,
                    max_samples: undefined,
                    max_instances: undefined,
                    max_samples_per_instance: undefined,
                },
                target: [/* 'topic', */ 'dataWriter'],
            },
            history: {
                defaultConfiguration: {
                    kind: 'KEEP_LAST',
                    depth: 1,
                },
                // Not RxO, and topic qos not available for the time being
                target: [/* 'topic', */ 'dataReader', 'dataWriter'],
            },
            latency_budget: {
                defaultConfiguration: {
                    duration: 0,
                },
                target: ['topic', 'dataReader', 'dataWriter'],
                rxo: true,
            },
            lifespan: {
                defaultConfiguration: {
                    duration: undefined,
                },
                target: [/* 'topic', */ 'dataWriter'],
            },
            liveliness: {
                defaultConfiguration: {
                    kind: 'AUTOMATIC',
                    lease_duration: undefined,
                },
                target: ['topic', 'dataReader', 'dataWriter'],
                rxo: true,
            },
            ownership: {
                defaultConfiguration: {
                    kind: 'SHARED',
                },
                target: ['topic', 'dataReader', 'dataWriter'],
                rxo: true,
            },
            ownership_strength: {
                defaultConfiguration: {
                    value: 0,
                },
                target: ['dataWriter'],
            },
            reliability: {
                component: 'reliability',
                defaultConfigurations: {
                    reader: {
                        kind: 'BEST_EFFORT',
                        max_blocking_time: 1e8, // 100ms
                    },
                    writer: {
                        kind: 'RELIABLE',
                        max_blocking_time: 1e8, // 100ms
                    },
                },
                target: ['dataReader', 'dataWriter'],
                rxo: true,
            },

            resource_limits: {
                defaultConfiguration: {
                    max_samples: 0,
                    max_instances: 0,
                    max_samples_per_instance: 0,
                },
                target: [/* 'topic', */ 'dataReader', 'dataWriter'],
            },
            transport_priority: {
                defaultConfiguration: {
                    value: 0,
                },
                target: [/* 'topic', */ 'dataWriter'],
            },
        },
    },
});

declare module '@nuxt/schema' {
    interface AppConfigInput {
        dds?: {
            policies?: {
                [policyName: string]: {
                    component?: string;
                    policyName?: string;
                    defaultConfiguration?: Record<string, any>;
                    defaultConfigurations?: {
                        reader: Record<string, any>;
                        writer: Record<string, any>;
                    };
                    rxo?: boolean;
                    target: string[];
                };
            };
        };
    }
}
