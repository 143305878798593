import * as x509 from '@peculiar/x509';

export default () => {
    function isX509Doc(
        value: MaybeRef<TX509Document> | string
    ): value is TX509Document {
        return (unref(value) as TX509Document).name !== undefined;
    }

    return async function (
        projectId: string,
        authorityCertRaw: MaybeRef<TX509Document> | string,
        value: MaybeRef<TX509Document>
    ) {
        try {
            if (!unref(authorityCertRaw) || !unref(value)) {
                return true;
            }

            if (isX509Doc(authorityCertRaw)) {
                const routerCert = new x509.X509Certificate(
                    unref(value).content
                );

                const authorityCert = new x509.X509Certificate(
                    unref(authorityCertRaw).content
                );

                const isValid = await routerCert.verify(authorityCert);
                return isValid;
            } else {
                const { data, error } = await useFetch(
                    `/api/projects/${projectId}/certs/validate`,
                    {
                        method: 'POST',
                        body: {
                            cert: value,
                        },
                    }
                );

                if (error.value) {
                    return false;
                }

                // We're not currently able to validate the router cert against the cert authority. Should be done using an async call.
                return !!data.value?.isValid;
            }
        } catch (e: any) {
            return false;
        }
    };
};
