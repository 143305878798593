import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/usr/src/nuxt-app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import defaults_9rtUot57OJ from "/usr/src/nuxt-app/node_modules/@nuxtseo/module/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_izaWKZ8rEu from "/usr/src/nuxt-app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/usr/src/nuxt-app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import defaults_TgNupsGd8e from "/usr/src/nuxt-app/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/defaults.mjs";
import i18n_yfWm7jX06p from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AOuQ1DYzjk from "/usr/src/nuxt-app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/usr/src/nuxt-app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import toaster_QqiLjnpzdj from "/usr/src/nuxt-app/layers/shared/plugins/toaster.ts";
import vuetify_UZZdWDIoGj from "/usr/src/nuxt-app/layers/vuetify-layer/plugins/vuetify.ts";
import JsonPretty_ugp8yHRcxH from "/usr/src/nuxt-app/layers/design-system/plugins/JsonPretty.ts";
import vue_paycard_0enz2du1wu from "/usr/src/nuxt-app/layers/design-system/plugins/vue-paycard.ts";
import head_xR53WE0jXG from "/usr/src/nuxt-app/plugins/head.ts";
import sentry_io_client_rwlFs1cIK8 from "/usr/src/nuxt-app/plugins/sentry.io.client.ts";
import vue_number_format_VslFHQBPtZ from "/usr/src/nuxt-app/plugins/vue.number-format.ts";
import init_74KpgUZBCI from "/usr/src/nuxt-app/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/init.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  defaults_9rtUot57OJ,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  defaults_TgNupsGd8e,
  i18n_yfWm7jX06p,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  toaster_QqiLjnpzdj,
  vuetify_UZZdWDIoGj,
  JsonPretty_ugp8yHRcxH,
  vue_paycard_0enz2du1wu,
  head_xR53WE0jXG,
  sentry_io_client_rwlFs1cIK8,
  vue_number_format_VslFHQBPtZ,
  init_74KpgUZBCI
]