import { helpers } from '@vuelidate/validators';

function shouldBeRegex(value: string) {
    if (!helpers.req(value)) {
        return true;
    }

    try {
        // eslint-disable-next-line no-new
        new RegExp(value);
        return true;
    } catch (e) {
        return false;
    }
}

export default helpers.withParams({ type: 'shouldBeRegex' }, shouldBeRegex);
