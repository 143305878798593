import { usePreferredDark } from '@vueuse/core';
import { useTheme } from 'vuetify';

const storeOptions = {
    persist: {
        paths: undefined,
        storage: persistedState.localStorage,
    },
};

export default defineStore(
    'settings',
    () => {
        const isDark = usePreferredDark();
        const theme = ref<string>('light');
        const cTheme = useTheme();
        const pinMenu = ref(false);

        if (isDark.value) {
            theme.value = 'dark';
        }
        cTheme.global.name.value = theme.value;

        const projectListView = ref<'list' | 'card'>('card');

        watchEffect(() => {
            cTheme.global.name.value = theme.value;
        });

        return {
            theme,
            projectListView,
            pinMenu,
            useTheme: (value: string) => (theme.value = value),
        };
    },
    storeOptions
);
