import validate from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45expired_45demo_45users_45global from "/usr/src/nuxt-app/middleware/01.expired-demo-users.global.ts";
import _02_45locked_45users_45global from "/usr/src/nuxt-app/middleware/02.locked-users.global.ts";
import manifest_45route_45rule from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45expired_45demo_45users_45global,
  _02_45locked_45users_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "forbidden-corpo": () => import("/usr/src/nuxt-app/middleware/forbidden-corpo.ts"),
  "forbidden-demo-users": () => import("/usr/src/nuxt-app/middleware/forbidden-demo-users.ts"),
  "reserved-demo-users": () => import("/usr/src/nuxt-app/middleware/reserved-demo-users.ts"),
  auth: () => import("/usr/src/nuxt-app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}