import * as Sentry from '@sentry/vue';
import useAppVersion from '~/composables/useAppVersion';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const router = useRouter();

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: config.public.sentry.dsn,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ['localhost', /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: config.public.sentry.environment,
        release: useAppVersion(),
    });
});
