import { helpers } from '@vuelidate/validators';

const shouldNotMatch = (pattern: RegExp) => {
    return (value: string) => {
        if (!helpers.req(value)) {
            return true;
        }

        return pattern.test(value) === false;
    };
};

export default shouldNotMatch;
