<template>
    <slot v-if="isTargetedUser" />
</template>

<script setup lang="ts">
import { OrgKind } from '@ZettaScaleLabs/continuum-node-client/dist/organization';

const organisationsStore = useOrganisationsStore();
const { organisations } = storeToRefs(organisationsStore);

const isTargetedUser = computed(
    () => organisations.value.at(0)?.kind === OrgKind.DEMO
);
</script>
